<template>
  <div class="auth_unauthorized">
    <a-result
      status="error"
      :title="lbls.menu.unauthorized ? lbls.menu.unauthorized : textauth"
    >
      <template #icon>
        <img :src="imgunauthor" />
      </template>
    </a-result>
  </div>
</template>
<script>
import { config } from "@/config/bzbsconfig.js";
import Cache from "@/helper/Cache.js";

export default {
  name: "AuthUnauthorized",
  data() {
    return {
      imgunauthor: config.themeUrl + "/img/unauthorized.svg",
      textauth: "You don't have Permission for this page",
      exAccount: null,
      isCreateAccount: false,
    };
  },
  created() {
    this.exAccount = Cache.get("exAccount");
    if (this.exAccount == null) {
      this.isCreateAccount = true;
    }
    if (this.exAccount && !this.exAccount?.owner_account) {
      let account_id = this.exAccount.account?.id;
      let owner_account = this.exAccount.owner_account?.id;
      this.isCreateAccount = account_id != owner_account;
    }
  },
  methods: {
    createOwnerAccount() {
      window.location = window.location.origin + "/createaccount";
    },
  },
};
</script>
<style lang="scss" scoped>
.auth_unauthorized {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  /* @include transform(translate(-50%, -50%)); */
}
</style>
