<template>
  <AppLayout>
    <div :class="`${$route.name}`" class="invite-member-page">
      <Breadcrumbs
        v-if="lbls && lbls.invite_page"
        :databreadcrumbs="breadcrumbs"
        :title="lbls.invite_page.title_page"
        :is-action-flex="true"
      >
      </Breadcrumbs>

      <div class="invite-email">
        <a-collapse
          v-model="activeKey"
          :expand-icon-position="expandIconPosition"
        >
          <a-collapse-panel key="1" :show-arrow="false">
            <template slot="header">
              <a-icon type="usergroup-add" class="icon-invite" />
              <span class="font-weight-bold ml-2 font-size-title">
                {{ lbls.invite_page.invite_member_by_email }}
              </span>
            </template>
            <a-icon
              slot="extra"
              :type="activeIcon == '1' ? 'shrink' : 'arrows-alt'"
              class="icon-invite"
              @click="handleClick"
            />
            <div class="invite-email-content">
              <div class="provider-email">
                <a-form-item
                  :label="lbls.invite_page.please_select_email_provider"
                >
                  <a-row :gutter="16">
                    <a-col class="gutter-row" :span="6">
                      <div class="gutter-box">
                        <a-radio-group
                          v-model="invite.emailServer"
                          name="radioGroupEmail"
                          button-style="solid"
                          class="w-100"
                          @change="onChangeEmailServer"
                        >
                          <a-radio :value="'office_365'">
                            <img
                              :src="urlImage.office_365"
                              style="max-width: 20px"
                              class="mr-1"
                            />
                            Office 365
                          </a-radio>
                        </a-radio-group>
                      </div>
                    </a-col>
                    <a-col class="gutter-row" :span="6">
                      <div class="gutter-box">
                        <a-radio-group
                          v-model="invite.emailServer"
                          name="radioGroupEmail"
                          button-style="solid"
                          class="w-100"
                          @change="onChangeEmailServer"
                        >
                          <a-radio :value="'hotmail_or_outlook'">
                            <img
                              :src="urlImage.microsoft"
                              style="max-width: 20px"
                              class="mr-1"
                            />
                            Hotmail/Outlook
                          </a-radio>
                        </a-radio-group>
                      </div>
                    </a-col>
                    <a-col class="gutter-row" :span="6">
                      <div class="gutter-box">
                        <a-radio-group
                          v-model="invite.emailServer"
                          name="radioGroupEmail"
                          button-style="solid"
                          class="w-100"
                          @change="onChangeEmailServer"
                        >
                          <a-radio :value="'google'">
                            <img
                              :src="urlImage.google"
                              style="max-width: 20px"
                              class="mr-1"
                            />
                            Google
                          </a-radio>
                        </a-radio-group>
                      </div>
                    </a-col>
                    <a-col class="gutter-row" :span="6">
                      <div class="gutter-box">
                        <a-radio-group
                          v-model="invite.emailServer"
                          name="radioGroupEmail"
                          button-style="solid"
                          class="w-100"
                          @change="onChangeEmailServer"
                        >
                          <a-radio :value="'local_account'">
                            <img
                              :src="urlImage.local"
                              style="max-width: 20px"
                              class="mr-1"
                            />
                            Local account
                          </a-radio>
                        </a-radio-group>
                      </div>
                    </a-col>
                  </a-row>
                </a-form-item>
              </div>
              <div class="email">
                <a-form-item :label="lbls.invite_page.email">
                  <a-select
                    mode="tags"
                    style="width: 100%"
                    :token-separators="[',', ' ']"
                    :placeholder="lbls.invite_page.placeholder_select_email"
                    :auto-size="{ minRows: 2, maxRows: 6 }"
                    @change="handleChangeEmail"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>

      <div class="invite-detail">
        <TableInvite />
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import TableInvite from "@/module/User/InviteMember/components/Table/TableInvite.vue";

export default {
  name: "InviteMemberPage",
  components: {
    AppLayout,
    TableInvite,
  },
  data() {
    return {
      permission_key: "user",
      action_key: "invite-view",
      breadcrumbs: [],
      expandIconPosition: "left",
      activeKey: "1",
      activeIcon: "1",
      invite: {
        email: [],
        emailServer: null,
      },
      urlImage: {
        office_365: require("@/assets/images/server_type/office-365.png"),
        microsoft: require("@/assets/images/server_type/microsoft.png"),
        google: require("@/assets/images/server_type/google.png"),
        local: require("@/assets/images/server_type/local.png"),
      },
      optionEmail: [],
    };
  },
  methods: {
    handleClick() {
      this.activeIcon = this.activeIcon === "1" ? "2" : "1";
    },
    onChangeEmailServer(e) {
      console.log("radio checked", e.target.value);
    },
    handleChangeEmail(value) {
      console.log(value);
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_mixin.scss";
.invite-member-page {
  .invite-email {
    label.ant-radio-wrapper {
      border: solid 1px #e3e3e3;
      padding: 8px;
      width: 100%;
    }
    .font-size-title {
      font-size: 16px;
    }
    .icon-invite {
      font-size: 20px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      cursor: default;
      pointer-events: none;
    }

    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-extra {
      cursor: pointer;
      pointer-events: all;
    }
  }
}
</style>
