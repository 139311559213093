<template>
  <div>
    <AppLayout>
      <div :class="`${$route.name}`" class="setting-user-page">
        <Breadcrumbs :databreadcrumbs="breadcrumbs" />
        <div class="setting-user-layout">
          <div class="setting-user-top">
            <div class="flex-center">
              <div class="mr-3">
                <router-link
                  :to="{
                    name: 'ManageUsers',
                  }"
                >
                  <span class="icon-custom-add icon-arrow-nex">
                    <a-icon type="arrow-left" />
                  </span>
                  <span> BACK </span>
                </router-link>
              </div>
              <a-divider type="vertical" />
              <div class="ml-3 flex-center">
                <div class="ml-1">
                  <div class="label-name-user">
                    {{ dataUser?.display_name }}
                    <span class="status-user ml-2">
                      <a-tag
                        v-if="dataUser?.is_active"
                        :color="'green'"
                        class="tag-status"
                      >
                        <a-badge
                          :status="'success'"
                          :text="lbls.user_page.title_status_active"
                        />
                      </a-tag>
                      <a-tag v-else :color="'red'" class="tag-status">
                        <a-badge
                          :status="'error'"
                          :text="lbls.user_page.title_status_inactive"
                        />
                      </a-tag>
                    </span>
                  </div>
                  <div class="label-email-user">
                    {{ dataUser?.email }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a-tabs :default-active-key="clickTabs" @change="callbackTabs">
            <a-tab-pane key="1" :tab="lbls.user_page.set_roles" force-render>
              <div v-if="clickTabs == '1'">1</div>
            </a-tab-pane>
            <a-tab-pane
              key="2"
              :tab="lbls.user_page.user_information"
              force-render
            >
              <div v-if="clickTabs == '2'">
                <UserInformation
                  v-if="clickTabs == '2'"
                  :datauser="dataUser"
                  @formtouch="handleFormTouch"
                />
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </AppLayout>
    <div class="custom-footer-setpermission">
      <a-space>
        <a-button type="primary" ghost @click="handleCancel()">
          {{ lbls.user_page.btn.cancel }}
        </a-button>
        <a-button :disabled="disablebtnok" type="primary" @click="handleSave()">
          {{ lbls.user_page.btn.save }}
        </a-button>
      </a-space>
    </div>
  </div>
</template>
<script>
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import Cache from "@/helper/Cache.js";
import manageUsersAPI from "@/core/Page/ManageUserAPI";
import UserInformation from "@/module/User/ManageUsers/components/Setting/UserInformation.vue";
import PageLeaveMixin from "@/mixin/PageLeaveMixin";

export default {
  name: "SettingUser",
  components: {
    AppLayout,
    UserInformation,
  },
  mixins: [PageLeaveMixin],
  data() {
    return {
      permission_key: "user",
      action_key: "setting",
      breadcrumbs: [],
      isShowSkeleton: true,
      dataUser: {},
      clickTabs: "1",
      disablebtnok: true,
      typePage: "role",
      formTouch: false,
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  created() {
    this.setBreadcrumbs();
    this.init();
    this.$root.$refs.save = this;
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      if (this.$route.params.id == undefined) {
        this.$router.push({ name: "ManageUsers" });
      }
      this.getUserById(this.$route.params.id);
    },
    setBreadcrumbs() {
      return new Promise((resolve) => {
        this.breadcrumbs = [];
        this.breadcrumbs.push({
          breadcrumbName: this.lbls.menu.usermanagement,
          name: "ManageUsers",
        });
        this.breadcrumbs.push({
          breadcrumbName: this.lbls.menu.setting,
          name: "ManageUsers",
        });

        resolve(this.breadcrumbs);
      });
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
        onOk: () => {
          this.$router.push({ name: "ManageUsers" });
        },
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
        this.$router.push({ name: "ManageUsers" });
      }, secondsToGo * 1000);
    },
    callbackTabs(key) {
      this.clickTabs = key;
    },
    async getUserById(id) {
      this.handleLoading(true);
      let params = {
        account_id: this.exAccount.account.id,
        user_id: id,
      };
      manageUsersAPI
        .getUser(params)
        .then((res) => {
          this.handleLoading(false);
          if (res.data && res.data.result) {
            this.dataUser = res.data.result;
            this.setBreadcrumbs().then(() => {
              this.breadcrumbs.push({
                breadcrumbName: this.dataUser.display_name,
              });
            });
          }
        })
        .catch((error) => {
          this.handleLoading(false);
          this.errorApi(error);
        });
    },
    handleFormTouch(type, isTouch, data) {
      console.log("handleFormTouch", type, isTouch, data);
      if (type == "user") {
        this.disablebtnok = !isTouch;
        this.dataUser = data;
        this.typePage = type;
        this.formTouch = isTouch;
      } else {
        this.disablebtnok = !isTouch;
        this.dataUser = data;
        this.typePage = type;
        this.formTouch = isTouch;
      }
    },
    handleCancel() {
      this.$router.push({ name: "ManageUsers" });
    },
    saveForm() {
      return new Promise((resolve) => {
        this.handleSave();
        resolve(true);
      });
    },
    handleSave() {
      this.formTouch = false;
      if (this.typePage == "user") {
        this.callAPIUpdateUser();
      } else {
        this.callAPIUpdateRole();
      }
    },
    callAPIUpdateUser() {
      console.log("callAPIUpdateUser");
      var valueSave = {
        account_id: this.exAccount.account.id,
        user_id: this.dataUser.id,
        is_active: this.dataUser.is_active,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageUsersAPI
        .postEditUser(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              setTimeout(() => {
                this.titleModal = this.lbls.user_page.modal.title_success;
                this.contentModal =
                  this.lbls.user_page.modal.content_update_success;
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callAPIUpdateRole() {
      console.log("callAPIUpdateRole");
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_mixin.scss";
.setting-user-page {
  .setting-user-layout {
    padding: 8px 16px;
    background-color: white;
    border-radius: 12px;
  }
  .setting-user-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid $black-black-4;
    .ant-divider,
    .ant-divider-vertical {
      top: 0 !important;
      height: 30px;
      background-color: $grey-grey-11;
    }
    .icon-arrow-nex {
      width: 15px;
      cursor: pointer;
    }
    .label-name-user {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      .status-user {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
      }
    }
    .label-email-user {
      color: $grey-grey-5;
      font-size: 12px;
      font-weight: normal;
    }
  }
}
</style>
