<template>
  <div>
    <AppLayout>
      <div
        :class="`${$route.name}`"
        class="campaigns-detail-main campaigns-detail-page page"
      >
        <Breadcrumbs
          :is-action-flex="true"
          :databreadcrumbs="breadcrumbs"
          :title="dataPageBreadcrumb.name"
        >
          <template v-if="!isShowSkeleton" #actions>
            <div class="action-list">
              <a-tooltip placement="topLeft">
                <template slot="title">
                  {{ lbls.role_page.btn.assign_applications_to_role }}
                </template>
                <router-link
                  :to="{
                    name: 'ManageAssignApplicationstoRole',
                    query: {
                      id: $route.query.id,
                    },
                  }"
                >
                  <a-icon type="plus-square" />
                </router-link>
              </a-tooltip>
              <a-tooltip placement="topLeft">
                <template slot="title">
                  {{ lbls.role_page.btn.manage }}
                </template>
                <router-link
                  :to="{
                    name: 'ManageRoleSetPerMission',
                    query: {
                      id: $route.query.id,
                    },
                  }"
                >
                  <a-icon type="setting" />
                </router-link>
              </a-tooltip>
            </div>
          </template>
        </Breadcrumbs>
        <a-card v-if="isShowSkeleton">
          <a-skeleton />
        </a-card>
        <div v-show="!isShowSkeleton" class="manage-role-assigns">
          <a-row class="flex-center">
            <a-col :sm="24" :md="11" :lg="11">
              <TableList
                :datatable="dataAvailableUser"
                :dataselect="idUserForAdd"
                :databtn="'add'"
                :record="record"
                @onsearch="onSearchAdd($event)"
                @checkboxall="onChangesCheckboxAddAll($event)"
                @setdataforsave="setDataForSave($event)"
                @callsaveuser="handleAddUserInRole($event)"
              />
            </a-col>
            <a-col :sm="4" :md="2" :lg="2" class="custom-btn">
              <div class="text-center">
                <div>
                  <a-tooltip>
                    <template slot="title">
                      {{ lbls.role_page.btn.add_user_select }}
                    </template>
                    <a-button
                      :disabled="idUserForAdd.length == 0"
                      type="primary"
                      class="vertical-initial"
                      @click="handleAddUserInRoles()"
                    >
                      <a-icon type="right" />
                    </a-button>
                  </a-tooltip>
                </div>
                <div class="mt-2">
                  <a-tooltip>
                    <template slot="title">
                      {{ lbls.role_page.btn.delete_user_select }}
                    </template>
                    <a-button
                      :disabled="idUserForDelete.length == 0"
                      type="primary"
                      class="vertical-initial"
                      :class="{
                        'btn-custom-delete': idUserForDelete.length > 0,
                      }"
                      @click="handleDeleteUserInRoles()"
                    >
                      <a-icon type="left" />
                    </a-button>
                  </a-tooltip>
                </div>
              </div>
            </a-col>
            <a-col :sm="24" :md="11" :lg="11">
              <TableList
                :datatable="dataUserList"
                :dataselect="idUserForDelete"
                :databtn="'delete'"
                :record="record"
                @onsearch="onSearchDelete($event)"
                @checkboxall="onChangesCheckboxDeleteAll($event)"
                @setdataforsave="setDataForSave($event)"
                @callsaveuser="handleDeleteUserInRole($event)"
              />
            </a-col>
          </a-row>
        </div>
        <!-- Modal -->
        <div v-if="isModalResponse">
          <ModalAlert
            :modalalert="modalAlert"
            :alertdata="alertData"
            @handlemodalok="handlemodalOk"
          >
          </ModalAlert>
        </div>
      </div>
    </AppLayout>
    <div class="custom-footer-setpermission">
      <a-button
        type="primary"
        :disabled="countUserList == 0 && dataDelete.length == 0"
        @click="handleSave()"
      >
        {{ lbls.role_page.btn.save }}
      </a-button>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageRole from "@/core/Page/ManageRoleAPI";
import Cache from "@/helper/Cache.js";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import ModalAlert from "@/components/Modal/Alertmodal.vue";
import TableList from "@/module/Role/ManageAssigntoRole/components/Table/TableListCustom";

export default {
  name: "ManageRole",
  components: {
    AppLayout,
    TableList,
    ModalAlert,
  },
  data() {
    return {
      permission_key: "role",
      action_key: "assign",
      breadcrumbs: [],
      isShowSkeleton: true,
      isModalResponse: false,
      idModal: "",
      titleModal: "",
      contentModal: "",
      searchDataAdd: "",
      searchDataDelete: "",
      record: {},
      dataAvailableUser: [],
      dataUserList: [],
      idUserForAdd: [],
      idUserForDelete: [],
      alertData: {},
      modalAlert: {},
      dataPageBreadcrumb: {},
      dataDelete: [],
      countUserList: 0,
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.exAccount = Cache.get("exAccount");
        Promise.all([this.callGetDetail()]).then((resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess) {
            this.callGetAvailableUser();
          } else {
            this.handleLoading(false);
          }
        });
      } else {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.rolemanagement,
        name: "ManageRoles",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.assigntorole,
        name: "InviteUsersDetail",
      });
    },
    callGetDetail() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
        manageRoleAPI
          .getDetailRole(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              this.record = res.data.result;
              this.dataPageBreadcrumb = res.data.result;
              resolve(isCallDetailSuccess);
            } else {
              resolve(isCallDetailSuccess);
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callGetAvailableUser() {
      console.log("callGetAvailableUser");
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.record.id,
      };
      manageRole
        .getAvailabletUser(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            _.forEach(res.data.result.items, (item) => {
              item.is_selected = false;
              item.dataAvailableUser = true;
              if (this.idUserForAdd.length > 0) {
                _.forEach(this.idUserForAdd, (id_add) => {
                  if (item.id == id_add) {
                    item.is_selected = true;
                  }
                });
              }
            });
            this.dataAvailableUser = res.data.result.items;
            this.dataAvailableUserStart = _.cloneDeep(res.data.result.items);
            if (this.searchDataAdd) {
              this.onSearchFunction(this.searchDataAdd, "dataAvailableUser");
            }
            this.callGetUserList();
          } else {
            this.handleLoading(false);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetUserList() {
      let params = {
        account_id: this.exAccount.account.id,
      };
      if (this.record) {
        if (this.record.id) {
          params.role_id = this.record.id;
        }
      }
      manageRole
        .getListUser(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            _.forEach(res.data.result.items, (item) => {
              item.is_selected = false;
              item.is_new = false;
              item.is_delete = false;
              item.dataUserList = true;
              _.forEach(this.idUserForDelete, (id_delete) => {
                if (item.id == id_delete) {
                  item.is_selected = true;
                }
              });
            });
            this.dataUserList = res.data.result.items;
            this.dataUserListStart = _.cloneDeep(res.data.result.items);
            if (this.searchDataDelete) {
              this.onSearchFunction(this.searchDataDelete, "dataUserList");
            }
            this.handleLoading(false);
            this.isShowSkeleton = false;
          } else {
            this.handleLoading(false);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callAddUserInRole(user_ids) {
      return new Promise((resolve) => {
        this.handleLoading(true);
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.record.id,
          user_ids: user_ids,
        };
        manageRole
          .postAddUserInRole(params)
          .then((res) => {
            if (res.data) {
              if (res.data.is_success) {
                this.titleModal = this.lbls.user_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_create_success;
              }
            }
            resolve(res.data);
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(error);
          });
      });
    },
    callDeleteUserInRole(user_ids) {
      return new Promise((resolve) => {
        this.handleLoading(true);
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.record.id,
          user_ids: user_ids,
        };
        manageRole
          .postDeleteUserInRole(params)
          .then((res) => {
            if (res.data) {
              if (res.data.is_success) {
                this.titleModal = this.lbls.user_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_delete_success;
                // this.openNotificationWithIcon("success");
              }
            }
            resolve(res.data);
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(error);
          });
      });
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      this.callGetAvailableUser();
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.lbls.user_page.modal.title_success,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
        window.location.reload();
      }, secondsToGo * 1000);
    },
    onChangesCheckboxAddAll(checked) {
      this.idUserForAdd = [];
      _.forEach(this.dataAvailableUser, (item) => {
        item.is_selected = checked;
      });
      this.setUserForAdd();
    },
    onChangesCheckboxDeleteAll(checked) {
      this.idUserForDelete = [];
      _.forEach(this.dataUserList, (item) => {
        item.is_selected = checked;
      });
      this.setUserForDelete();
    },
    setDataForSave(item) {
      if (item.dataUserList) {
        this.setUserForDelete();
      }
      if (item.dataAvailableUser) {
        this.setUserForAdd();
      }
    },
    setUserForAdd() {
      _.forEach(this.dataAvailableUser, (item) => {
        if (item.is_selected) {
          this.idUserForAdd.push(item.id);
        } else {
          this.idUserForAdd = this.idUserForAdd.filter((data) => {
            return data != item.id;
          });
        }
      });
      this.idUserForAdd = [...new Set(this.idUserForAdd)];
    },
    setUserForDelete() {
      _.forEach(this.dataUserList, (item) => {
        if (item.is_selected) {
          this.idUserForDelete.push(item.id);
        } else {
          this.idUserForDelete = this.idUserForDelete.filter((data) => {
            return data != item.id;
          });
        }
      });
      this.idUserForDelete = [...new Set(this.idUserForDelete)];
    },
    onSearchAdd(e) {
      console.log("onSearchAdd : ", e);
      this.searchDataAdd = e;
      if (this.oldText != this.searchDataAdd) {
        this.oldTextAdd = this.searchDataAdd;
        console.log("searchDataAdd : ", this.searchDataAdd);
        this.onSearchFunction(this.searchDataAdd, "dataAvailableUser");
      }
    },
    onSearchDelete(e) {
      this.searchDataDelete = e;
      if (this.oldText != this.searchDataDelete) {
        this.oldTextDelete = this.searchDataDelete;
        this.onSearchFunction(this.searchDataDelete, "dataUserList");
      }
    },
    onSearchFunction(text, key) {
      if (this.oldText != this.searchDataAdd) {
        let keystart = key + "Start";
        this.oldText = this.searchData;
        this[key] = this[keystart].filter((item) => {
          return item.email
            .toString()
            .toLowerCase()
            .includes(text.toLowerCase());
        });
        this[key].sort((a, b) => b.is_selected - a.is_selected);
      }
    },
    handleDeleteUserInRole(user_id) {
      this.dataUserList = this.dataUserList.filter((item) => {
        if (!item.is_new && item.id == user_id) {
          this.dataDelete.push(item.id);
          item.dataAvailableUser = true;
          item.dataUserList = false;
          item.is_selected = false;
          this.dataAvailableUser.push(item);
          this.dataAvailableUserStart.push(item);
          this.$nextTick(() => {
            this.$forceUpdate();
          });
          return item.id != user_id;
        } else if (item.is_new && item.id == user_id) {
          item.dataAvailableUser = true;
          item.dataUserList = false;
          item.is_selected = false;
          this.dataAvailableUser.push(item);
          this.dataAvailableUserStart.push(item);
          this.$nextTick(() => {
            this.$forceUpdate();
          });
          return item.id != user_id;
        } else {
          return item;
        }
      });
    },
    handleDeleteUserInRoles() {
      console.log("handleDeleteUserInRoles : ", this.idUserForDelete);
      if (this.idUserForDelete.length > 0) {
        this.idUserForDelete.forEach((user_id) => {
          this.dataUserList = this.dataUserList.filter((item) => {
            if (!item.is_new && item.id == user_id) {
              this.dataDelete.push(item.id);
              item.dataAvailableUser = true;
              item.dataUserList = false;
              item.is_selected = false;
              this.dataAvailableUser.push(item);
              this.dataAvailableUserStart.push(item);
              this.$nextTick(() => {
                this.$forceUpdate();
              });
              return item.id != user_id;
            } else if (item.is_new && item.id == user_id) {
              item.dataAvailableUser = true;
              item.dataUserList = false;
              item.is_selected = false;
              this.dataAvailableUser.push(item);
              this.dataAvailableUserStart.push(item);
              this.$nextTick(() => {
                this.$forceUpdate();
              });
              return item.id != user_id;
            } else {
              return item;
            }
          });
        });
      }
    },
    handleAddUserInRole(user_id) {
      console.log("handleAddUserInRole : ", user_id);
      this.countUserList += 1;
      this.dataAvailableUserStart = this.dataAvailableUserStart.filter(
        (item) => {
          if (item.id == user_id) {
            return item.id != user_id;
          } else {
            return item;
          }
        }
      );

      this.dataAvailableUser = this.dataAvailableUser.filter((item) => {
        if (item.id == user_id) {
          item.dataAvailableUser = false;
          item.dataUserList = true;
          item.is_new = true;
          item.is_selected = false;
          this.dataUserList.push(item);
          this.$nextTick(() => {
            this.$forceUpdate();
          });
          return item.id != user_id;
        } else {
          return item;
        }
      });
    },
    handleAddUserInRoles() {
      console.log("handleAddUserInRoles : ", this.idUserForAdd);
      this.countUserList += 1;
      if (this.idUserForAdd.length > 0) {
        if (this.dataUserList.length > 0) {
          const idSet = new Set(this.dataUserList.map((item) => item.id));
          this.idUserForAdd = this.idUserForAdd.filter((item) => {
            if (!idSet.has(item)) {
              return item;
            }
          });
        }

        this.idUserForAdd.forEach((user_id) => {
          this.dataAvailableUserStart = this.dataAvailableUserStart.filter(
            (item) => {
              if (item.id == user_id) {
                return item.id != user_id;
              } else {
                return item;
              }
            }
          );

          this.dataAvailableUser = this.dataAvailableUser.filter((item) => {
            if (item.id == user_id) {
              item.dataAvailableUser = false;
              item.dataUserList = true;
              item.is_new = true;
              item.is_selected = false;
              this.dataUserList.push(item);
              this.$nextTick(() => {
                this.$forceUpdate();
              });
              return item.id != user_id;
            } else {
              return item;
            }
          });
        });
      }
    },
    handleSave() {
      console.log("Save : ", this.dataDelete);
      if (this.dataDelete.length > 0) {
        console.log("delete");
        this.callDeleteUserInRole(this.dataDelete).then(() => {
          if (this.dataUserList.length > 0) {
            var user_ids = [];
            this.dataUserList.filter((item) => {
              if (item.is_new) {
                user_ids.push(item.id);
              }
            });

            if (user_ids.length > 0) {
              this.callAddUserInRole(user_ids).then(() => {
                this.showModalSuccess();
              });
            } else {
              this.showModalSuccess();
            }
          } else {
            this.showModalSuccess();
          }
        });
      } else {
        if (this.dataUserList.length > 0) {
          var user_ids = [];
          this.dataUserList.filter((item) => {
            if (item.is_new) {
              user_ids.push(item.id);
            }
          });

          if (user_ids.length > 0) {
            console.log("user_ids : ", user_ids);
            this.callAddUserInRole(user_ids).then(() => {
              this.showModalSuccess();
            });
          } else {
            this.showModalSuccess();
          }
        } else {
          this.showModalSuccess();
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.manage-role-assigns {
  overflow: auto;
  padding-right: 8px;
  padding-bottom: 16px;
  height: calc(100vh - 215px);

  .ant-btn {
    min-width: inherit;
  }
}
</style>
