<template>
  <AppLayout>
    <div :class="`${$route.name}`" class="manage-user-page">
      <Breadcrumbs
        v-if="lbls && lbls.user_page"
        :databreadcrumbs="breadcrumbs"
        :title="lbls.user_page.title_page"
        :is-action-flex="true"
        :description="lbls.user_page.title_table_description"
      >
      </Breadcrumbs>

      <a-tabs :default-active-key="clickTabs" @change="callbackTabs">
        <a-tab-pane key="1" :tab="lbls.user_page.all_member" force-render>
          <div v-if="clickTabs == '1'">
            <TableUser />
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="lbls.user_page.invitation_detail" force-render>
          <div v-if="clickTabs == '2'">
            <TableInvite />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </AppLayout>
</template>

<script>
import Cache from "@/helper/Cache.js";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import TableUser from "@/module/User/ManageUsers/components/Table/TableUser.vue";
import TableInvite from "@/module/User/ManageUsers/components/Table/TableInvite.vue";

export default {
  name: "ManageUser",
  components: {
    AppLayout,
    TableUser,
    TableInvite,
  },
  data() {
    return {
      permission_key: "user",
      action_key: "view",
      breadcrumbs: [],
      isShowSkeleton: true,
      clickTabs: "1",
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  mounted() {},
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.usermanagement,
        name: "ManageUsers",
      });
    },
    callbackTabs(key) {
      this.clickTabs = key;
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.manage-user-page {
  .ant-tabs {
    min-height: 70vh;
  }
}
</style>
