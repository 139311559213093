<template>
  <AppLayout>
    <div
      :class="`${$route.name}`"
      class="campaigns-detail-main campaigns-detail-page page"
    >
      <Breadcrumbs
        :is-action-flex="true"
        :databreadcrumbs="breadcrumbs"
        :title="lbls.invite_account_page.title_page"
      >
        <template v-slot:actions>
          <a-input-search
            v-model="searchData"
            :placeholder="lbls.invitation_page.input_search"
            class="input-search"
            size="large"
            @search="onSearch(searchData)"
          >
            <template slot="suffix">
              <a-icon
                v-if="isSearchData"
                type="close-circle"
                theme="filled"
                class="icon-close-search-input"
                @click="closeSearchData()"
              />
            </template>
          </a-input-search>
          <a-button
            class="ml-3"
            type="primary"
            :disabled="!isPermissionInvite"
            @click="createOwnerAccount"
          >
            <a-icon type="plus-circle" />
            {{ lbls.create_account.btn.create }}
          </a-button>
        </template>
      </Breadcrumbs>
      <!-- <div class="row">
        <PageHeader @onsearch="onSearch($event)" />
      </div> -->
      <a-card v-if="isShowSkeleton">
        <a-skeleton />
      </a-card>
      <div v-else>
        <TableList
          :datatable="data"
          :table-height="tableHeight"
          :conditiontable="conditionTable"
          @onsearchtable="onSearchTable($event)"
          @callgetlist="callGetList"
          @handledelete="handleDelete($event)"
          @handleresend="handleResendInvite($event)"
        >
        </TableList>
        <ShowTotal
          :datashowtitle="data"
          :conditiontable="conditionTable"
          :pagesize="page_size"
          :totallist="totalList"
          @callgetlist="callGetList"
          @setpagesize="setPageSize($event)"
        />
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/Layout/LayoutMaster.vue";
//import PageHeader from "@/module/Cross/ManageInvitationAccount/components/Layout/PageHeader.vue";
import ShowTotal from "@/components/Layout/ShowTotal";
import TableList from "@/module/Cross/ManageInvitationAccount/components/Table/TableList";
import Cache from "@/helper/Cache.js";
import manageUsersAPI from "@/core/Page/ManageUserAPI";

export default {
  name: "InvitationAccount",
  components: {
    AppLayout,
    // PageHeader,
    ShowTotal,
    TableList,
  },
  data() {
    return {
      isSearchData: false,
      isPermissionInvite: false,
      permission_action_key: "cross-account-create",
      permission_key: "cross",
      action_key: "view",
      breadcrumbs: [],
      isShowSkeleton: true,
      isShowModal: false,
      isModalResponse: false,
      isDelete: false,
      data: {},
      searchData: "",
      nameSearch: "",
      idModal: "",
      titleModal: "",
      modalbtnok: "",
      contentModal: "",
      conditionTable: {},
      alertData: {},
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      page: 0,
      page_size: 25,
      totalList: 0,
      tableHeight: 0,
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.calTableHeight);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.calTableHeight);
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.setCallGetList().then(() => {
        this.calTableHeight();
      });
      if (this.$store.state.menu?.length) {
        this.handlePermissionPage(this.permission_key, this.action_key);
        let permission_page = this.handlePermissionPage(this.permission_key);
        if (permission_page?.length) {
          this.isPermissionInvite = this.checkArrowPermission(
            this.permission_action_key,
            permission_page
          );
        }
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.crossaccount,
        name: "ManageCrossAccount",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.invitation_account,
        name: "InvitationAccount",
      });
    },
    createOwnerAccount() {
      this.$router.push({ name: "CreateOwnerAccount" });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.isSearchData = this.searchData != "";
        this.nameSearch = text != undefined ? text : "";
        this.setCallGetList();
      } else {
        return;
      }
    },
    closeSearchData(searchData) {
      this.searchData = "";
      this.onSearch(searchData);
      this.$forceUpdate();
    },
    handlemodalOk() {
      if (this.isDelete) {
        this.isShowSkeleton = true;
        this.callPostDeleteData();
      }
    },
    handleDelete(record) {
      this.idModal = "confirm";
      this.isDelete = true;
      this.modalbtnok = this.lbls.invitation_page.modal.btn.delete;
      this.titleModal = this.lbls.invitation_page.modal.title_confirm;
      this.contentModal = this.lbls.invitation_page.modal.content_delete + " ?";
      this.objRecord = record;
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: this.lbls.invitation_page.modal.content_delete,
        content: this.objRecord.invited_email,
        okText: this.lbls.invitation_page.modal.btn.delete,
        okType: "danger",
        cancelText: this.lbls.invitation_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDeleteData();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    handleResendInvite(record) {
      this.idModal = "confirm";
      this.objRecord = record;
      console.log("this.objRecord : ", this.objRecord);
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: "Resend invitation?",
        content: this.objRecord.account_name,
        okText: "OK",
        cancelText: "Cancel",
        closable: true,
        onOk: () => {
          this.handleLoading(true);
          this.callPostResendInvite();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      this.setCallGetList();
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      this.setCallGetList();
    },
    setCallGetList() {
      return new Promise((resolve) => {
        this.isShowSkeleton = true;
        this.page = 0;
        this.page_size = this.page_size;
        this.data = [];
        this.callGetList().then(() => {
          resolve();
        });
      });
    },
    setPageSize(value) {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = value;
      this.data = [];
      this.callGetList();
    },
    callGetList() {
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        page: this.page,
        page_size: this.page_size,
      };
      if (this.nameSearch) {
        params.filter = this.nameSearch;
      }
      return new Promise((resolve, reject) => {
      manageUsersAPI
        .getInvitationCreateAccount(params)
        .then((res) => {
          if (res.data) {
            if (res.data.result) {
              let item = res.data.result.items;
              let paging = res.data.result.paging;
              if (item.length > 0) {
                this.data.push(...res.data.result.items);
                this.data.forEach((elements) => {
                  let first_name = elements.first_name
                    ? elements.first_name + " "
                    : "-";
                  let last_name = elements.last_name ? elements.last_name : "";
                  let display_name = elements.display_name
                    ? elements.display_name
                    : "-";
                  elements.full_name = first_name + last_name;
                  elements.display_name = display_name;
                  elements.isShowMore = false;
                });
              }
              this.totalList = this.data.length;
              this.conditionTable = {
                hasMoreData: paging.has_more_data,
                isFilters: false,
              };
            }
          }
          this.$nextTick(() => {
              this.handleLoading(false);
              this.isShowSkeleton = false;
              resolve(res.data);
            });
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            reject(error);
          });
      });
    },
    callPostDeleteData() {
      let params = {
        account_id: this.exAccount.account.id,
        invitation_id: this.objRecord.id,
      };
      manageUsersAPI
        .deleteInvitation(params)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.isModalProcess = false;
            setTimeout(() => {
              this.titleModal = this.lbls.invitation_page.modal.title_success;
              this.contentModal =
                this.lbls.invitation_page.modal.content_delete_success;
              // this.openNotificationWithIcon("success");
              this.showModalSuccess();
              this.handleLoading(false);
            }, 500);
          }
        })
        .catch((error) => {
          this.isDelete = false;
          this.handleLoading(false);
          this.errorApi(error);
        });
    },
    callPostResendInvite() {
      let params = {
        account_id: this.exAccount.account.id,
        invite_id: this.objRecord.id,
      };
      manageUsersAPI
        .postResendInvite(params)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.isModalProcess = false;
            setTimeout(() => {
              this.contentModal = this.lbls.invitation_page.modal.title_success;
              this.showModalSuccess();
              this.handleLoading(false);
            }, 500);
          }
        })
        .catch((error) => {
          this.handleLoading(false);
          this.errorApi(error);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
</style>
