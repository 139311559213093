export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.calTableHeight();
    },
    calTableHeight() {
      if (!document.querySelector(".ant-table-wrapper")) return;
      var topTable = document
        .querySelector(".ant-table-wrapper")
        .getBoundingClientRect().top;
      this.tableHeight = window.innerHeight - (topTable + 37 + 21 + 16 + 16);
    },
  },
};
