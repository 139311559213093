<template>
  <div class="campaign-list-table">
    <a-table
      id="campaign-list-table"
      row-key="id"
      :columns="columns"
      :data-source="data"
      :scroll="{ y: innerHeight - 330, x: 800 }"
      :pagination="false"
      :locale="{
        filterConfirm: lbls.package_page.btn.ok,
        filterReset: lbls.package_page.btn.reset,
      }"
      @change="handleTableChange"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput = c)"
          :placeholder="`${lbls.btn.search}${column.title}`"
          :value="selectedKeys[0]"
          style="
            width: 188px;
            margin-bottom: 8px;
            display: block;
            font-size: 12px;
          "
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        />
        <a-button
          type="primary"
          style="width: 90px; margin-right: 8px"
          @click="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        >
          {{ lbls.btn.search }}
        </a-button>
        <a-button
          style="width: 90px"
          @click="() => handleTableReset(clearFilters)"
        >
          {{ lbls.btn.reset }}
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="template-package" slot-scope="text, record">
        <div class="column-name-text-title">
          {{ text }}
        </div>
        <div class="column-name-text-caption">
          {{ record.package }}
        </div>
      </template>
      <template slot="template-type" slot-scope="text, record">
        <div>
          {{ record.type ? record.type : "Standard" }}
        </div>
        <div v-if="record.specific_account" class="column-name-text-caption">
          ({{ record.specific_account.name }})
        </div>
      </template>
      <template slot="template-products" slot-scope="text">
        <div class="row-name-custom">
          <div v-if="text.length > 0">
            <div class="d-flex align-items-end">
              <div class="item-name text-ellipsis">
                {{ text[0].display_name }}
              </div>
              <div class="item-see-more">
                <div
                  v-if="text.length > 1"
                  class="column-name-text-caption ml-1"
                >
                  ({{ text.length }})
                </div>
                <div v-if="text.length > 1" class="btn-toggle">
                  <a-tooltip placement="top">
                    <template slot="title">
                      {{ lbls.package_page.btn.more }}
                    </template>
                    <a @click="handleClickIcon(text, 'List')">
                      <a-icon type="monitor" />
                    </a>
                  </a-tooltip>
                </div>
              </div>
            </div>
            <div class="column-name-text-caption text-ellipsis">
              {{ text[0].name }}
            </div>
          </div>
          <div v-else>-</div>
        </div>
      </template>
      <template slot="template-application" slot-scope="text, record">
        <div class="action-application">
          <a-tooltip v-if="record.application_count > 0">
            <template slot="title"> {{ lbls.package_page.btn.more }} </template>
            <a @click="handleView(record, 'View')">
              {{ text }}
            </a>
          </a-tooltip>
          <div v-else>{{ text }}</div>
        </div>
      </template>
      <template slot="template-action" slot-scope="record">
        <div class="action-list">
          <a-tooltip v-if="isPermissionEdit" placement="topLeft">
            <template slot="title">
              {{ lbls.package_page.btn.add_app }}
            </template>
            <a @click="handleAdd(record)">
              <a-icon type="plus-circle" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "ManageProduct",
  components: {},
  props: {
    datatable: {
      type: Array,
    },
    conditiontable: {
      type: Object,
    },
  },
  data() {
    return {
      permission_key: "package",
      innerHeight: 0,
      lastscrollTop: 0,
      totalList: 0,
      tableContentHeight: 0,
      tableContentWidth: 0,
      searchInput: null,
      data: [],
      dataStart: [],
      appsFilters: [],
      lbltable: [],
      isFilters: false,
      columns: [
        {
          id: "Package",
          title: "Package",
          keys: "tbl_colum_package",
          dataIndex: "package_display_name",
          scopedSlots: {
            customRender: "template-package",
          },
          sorter: (a, b) => a.package.localeCompare(b.package),
        },
        {
          id: "Package Type",
          title: "Package Type",
          keys: "tbl_colum_package_type",
          dataIndex: "type",
          width: 250,
          scopedSlots: {
            customRender: "template-type",
          },
          filters: [
            {
              text: "standard",
              key: "option_package",
              value: "Standard",
            },
            {
              text: "hidden",
              key: "option_package",
              value: "Hidden",
            },
            {
              text: "customized",
              key: "option_package",
              value: "Customized",
            },
          ],
          onFilter: (value, record) => record.type === value,
        },
        {
          id: "Product list",
          title: "Product list",
          keys: "tbl_colum_product_list",
          dataIndex: "products",
          scopedSlots: {
            customRender: "template-products",
          },
        },
        {
          id: "Application",
          title: "Application",
          keys: "tbl_colum_application",
          dataIndex: "application_count",
          align: "center",
          fixed: "right",
          width: 120,
          scopedSlots: { customRender: "template-application" },
        }
      ],
      isPermissionView: true,
      isPermissionEdit: true,
      iconArrow: require("@/assets/images/Icon/Icon_arrow-down-primary.svg"),
    };
  },
  watch: {
    datatable() {
      this.setData();
    },
    lbls() {
      this.columns = _.cloneDeep(this.columnsStart);
      this.setColumnTable();
    },
    "$store.state.menu"() {
      this.handlePermission();
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.tableContent = document.querySelector(".ant-table-body");
    this.tableContentHeight =
      this.tableContent.offsetHeight + this.setHeightDefualt;
    this.onScroll();
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.datatable;
      this.dataStart = this.datatable;
      this.columnsStart = _.cloneDeep(this.columns);
      this.setColumnTable();
      this.handlePermission();
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        this.isPermissionView = permission_page.includes("package-view");
        this.isPermissionEdit = permission_page.includes("package-edit");
      }
    },
    setColumnTable() {
      this.lbltable = this.lbls.package_page;
      _.forEach(this.columns, (column) => {
        if (column.keys) {
          column.title = this.lbls.package_page[column.keys];
        }
        if (column.filters) {
          _.forEach(column.filters, (filter) => {
            let isobject =
              typeof this.lbltable?.table_list[filter.key] === "object";
            if (isobject) {
              filter.text = this.lbltable?.table_list[filter.key][filter.text];
            } else {
              filter.text = this.lbltable?.table_list[filter.key];
            }
          });
        }
      });
    },
    setData() {
      this.data = this.datatable;
    },
    onScroll() {
      this.tableContent.addEventListener("scroll", (event) => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = event.target.scrollTop;
        let isScrollDown = this.lastscrollTop !== event.target.scrollTop;
        let scrollPersent = (currentScroll / maxScroll) * 100;
        if (
          scrollPersent >= 95 &&
          this.conditiontable.hasMoreData &&
          isScrollDown &&
          !this.isFilters
        ) {
          this.conditiontable.hasMoreData = false;
          this.handleLoading(true);
          this.callGetList();
        } else {
          return;
        }
        this.lastscrollTop = event.target.scrollTop;
      });
    },
    handleTableSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleTableReset(clearFilters) {
      clearFilters();
      this.searchText = "";
      this.valueStatus = "";
      this.data = this.dataStart;
    },
    handleAdd(record) {
      this.$emit("handleadd", record);
    },
    handleView(record, action) {
      let data = {
        record: record,
        btnaction: action,
      };
      this.$emit("callback", data);
    },
    callGetList() {
      this.$emit("callgetlist");
    },
    onResize() {
      this.innerHeight = window.innerHeight;
      this.$forceUpdate();
    },
    checkLayout() {
      let tableWidth = document.querySelector(".ant-table-tbody");
      this.tableContentWidth = tableWidth.offsetWidth;
      this.columns[this.columns.length - 1] = this.unFixedColumn(this.columns);
      if (this.boxDetailWidth() <= this.tableContentWidth) {
        this.columns[this.columns.length - 1] = this.fixedColumn(this.columns);
      }
    },
    handleTableChange(pagination, filters, sorte, currentData) {
      console.log(pagination, filters, sorte);
      this.isFilters = this.checkFilters(filters);
      let onsearchData = {
        totalList: currentData.currentDataSource.length,
        isFilters: this.isFilters,
      };
      this.$emit("onsearchtable", onsearchData);
    },
    checkFilters(filters) {
      let isFilters = false;
      let itemfilters = Object.values(filters).flat();
      isFilters = itemfilters.length > 0;
      return isFilters;
    },
    handleClickIcon(record, action, is_product) {
      let data = {
        record: record,
        btnaction: action,
      };
      if (is_product) {
        data.is_product = is_product;
      }
      this.$emit("callback", data);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.ant-layout {
  .row-name-custom {
    .text-ellipsis {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .item-name {
      max-width: calc(100% - 46px);
    }
    .item-see-more {
      display: flex;
      align-items: center;
      width: 46px;
    }
  }
}
</style>
