<template>
  <AppLayout>
    <div
      :class="`${$route.name}`"
      class="campaigns-detail-main campaigns-detail-page page"
    >
      <Breadcrumbs
        :is-action-flex="true"
        :databreadcrumbs="breadcrumbs"
        :title="lbls.applicationaccount_page.title_page"
      >
        <template v-slot:actions>
          <a-input-search
        v-model="searchData"
        :placeholder="lbls.applicationaccount_page.input_search"
        class="input-search"
        size="large"
        @search="onSearch(searchData)"
      >
        <template slot="suffix">
          <a-icon
            v-if="isSearchData"
            style="margin-right: 5px; font-size: 10px"
            type="close-circle"
            theme="filled"
            class="icon-close-search-input"
            @click="closeSearchData()"
          />
        </template>
      </a-input-search>
      <a-button
        v-if="isPermissionCrossTransfer"
        type="primary"
        ghost
        @click="
          goToPageAndQuery(
            'TransferOwnerAccount',
            dataPageHeader?.cross_account_id,
            dataPageHeader?.account_name
          )
        "
      >
        <a-icon type="retweet" />
        {{ lbls.menu.transferowneraccount }}
      </a-button>
      <a-button
        type="primary"
        :disabled="!isPermissionAssignApp"
        @click="handleCreate()"
      >
        <a-icon type="plus-circle" />
        {{ lbls.applicationaccount_page.btn.assign }}
      </a-button>
        </template>
      </Breadcrumbs>
      <div v-if="isShowSkeleton" class="background-white p-5">
        <a-skeleton />
      </div>
      <div v-else>
        <div id="box-detail" class="manage-page-detail">
          <TableList
            :datatable="data"
            :conditiontable="conditionTable"
            @callgetlist="callGetList"
            @handledelete="handleDelete($event)"
          />
          <ShowTotal
            :datashowtitle="data"
            :conditiontable="conditionTable"
            :pagesize="page_size"
            :totallist="totalList"
            @callgetlist="callGetList"
            @setpagesize="setPageSize($event)"
          />
        </div>
      </div>
      <!-- Modal -->
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageCrossAccountAPI from "@/core/Page/ManageCrossAccountAPI";
import Cache from "@/helper/Cache.js";
import ShowTotal from "@/components/Layout/ShowTotal";
import TableList from "@/module/Cross/ManageApplicationAccount/components/Table/TableList";
import ModalProcess from "@/module/Cross/ManageApplicationAccount/components/Modal/ModalProcess.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";

export default {
  name: "ManageUser",
  components: {
    AppLayout,
    ShowTotal,
    TableList,
    ModalAlert,
    ModalProcess,
  },
  data() {
    return {
      isSearchData: false,
      isPermissionAssignApp: true,
      isPermissionCrossTransfer: false,
      permission_key: "cross",
      action_key: "existing-view",
      data: [],
      breadcrumbs: [],
      totalList: 0,
      nameSearch: "",
      isShowSkeleton: true,
      isModalResponse: false,
      idModal: "",
      titleModal: "",
      modalbtnok: "",
      contentModal: "",
      page: 0,
      page_size: 25,
      accountName: "",
      isModalProcess: false,
      titleModalProcess: "",
      objDataSave: [],
      isCreate: false,
      isEdit: false,
      isDelete: false,
      conditionTable: {},
      alertData: {},
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      modalprocess: {
        visible: false,
        title: "",
        key: "",
        message: "",
        okText: "",
        cancelText: "",
        isCreate: false,
        isEdit: false,
      },
      dataPageHeader: {},
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  created() {
    this.setBreadcrumbs();
    this.init();
    this.handlePermission();
  },
  methods: {
    init() {
      if (this.$route.query.id && this.$route.query.name) {
        this.accountName = this.$route.query.name;
        this.exAccount = Cache.get("exAccount");
        this.dataPageHeader = {
          cross_account_id: this.$route.query.id,
          account_name: this.$route.query.name,
        };
        Promise.all([this.callGetList()]).then((resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess) {
            this.moreData = true;
          } else {
            this.handleLoading(false);
          }
        });
      } else {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.crossaccount,
        name: "ManageCrossAccount",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.$route.query.name,
        name: "ManageCrossAccount",
      });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.nameSearch = text != undefined ? text : "";
        this.isSearchData = this.searchData != "";
        this.$emit("onsearch", this.searchData);
        this.setCallGetList();
      } 
      else {
        return;
      }
    },
    closeSearchData(searchData) {
      this.searchData = "";
      this.onSearch(searchData);
      this.$forceUpdate();
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        if (permission_page?.length) {
          this.isPermissionAssignApp = this.checkArrowPermission(
            "cross-existing-add",
            permission_page
          );
          this.isPermissionCrossTransfer = this.checkArrowPermission(
            "cross-transfer-owner-adjust",
            permission_page
          );
        }
      }
    },
    handleCreate() {
      this.$emit("handlecreate");
      this.resetCondition();
      this.handleLoading(true);
      this.isCreate = true;
      this.titleModalProcess =
        this.lbls.applicationaccount_page.drw_title_create;
      this.showModalProcess();
    },
    handleDelete(record) {
      this.resetCondition();
      this.isDelete = true;
      this.idModal = "confirm";
      this.objRecord = record;
      this.$confirm({
        class: "custom-width-confirm",
        centered: true,
        autoFocusButton: null,
        title: this.lbls.applicationaccount_page.modal.content_delete,
        content: record.name,
        okText: this.lbls.applicationaccount_page.modal.btn.delete,
        okType: "danger",
        cancelText: this.lbls.user_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDeleteData();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    handlemodalOk() {
      if (this.idModal == "error") return;
      this.handleLoading(true);
      if (this.isDelete) {
        this.callPostDeleteData();
      }
      if (this.isCreate) {
        this.callPostAddData();
      }
      this.isModalResponse = false;
    },
    actionModal(value) {
      if (value == "close") {
        setTimeout(() => {
          this.isModalProcess = false;
          return;
        }, 500);
      } else {
        this.handleLoading(true);
        if (this.isCreate || this.isEdit) {
          this.objDataSave = value;
          this.callPostAddData();
        }
      }
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      this.setCallGetList();
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      this.setCallGetList();
    },
    showModalProcess() {
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        key: "applicationaccount_page.",
        isCreate: this.isCreate,
        isEdit: this.isEdit,
      };
      this.isModalProcess = true;
      this.handleLoading(false);
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    resetCondition() {
      this.isCreate = false;
      this.isDelete = false;
    },
    setCallGetList() {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = this.page_size;
      this.data = [];
      this.callGetList();
    },
    setPageSize(value) {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = value;
      this.data = [];
      this.callGetList();
    },
    callGetList() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        this.page = this.page + 1;
        let params = {
          account_id: this.exAccount.account.id,
          cross_account_id: this.$route.query.id,
          page: this.page,
          page_size: this.page_size,
        };
        if (this.nameSearch) {
          params.filter = this.nameSearch;
        }
        manageCrossAccountAPI
          .getSearchBzbsApplication(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              let item = res.data.result.items;
              let paging = res.data.result.paging;
              this.hasMoreData = false;
              if (item.length > 0) {
                this.data.push(...res.data.result.items);
              }
              if (paging && paging.has_more_data) {
                this.hasMoreData = true;
                this.totalList = this.totalList;
              }
              this.totalList = this.data.length;
              this.conditionTable = {
                hasMoreData: paging.has_more_data,
                isFilters: false,
              };
              this.product = res.data.result;
              resolve(isCallDetailSuccess);
            }
            resolve(isCallDetailSuccess);
            this.handleLoading(false);
            this.isShowSkeleton = false;
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callPostDeleteData() {
      let params = {
        account_id: this.exAccount.account.id,
        cross_account_id: this.$route.query.id,
        buzzebees_application_Ids: [this.objRecord.id],
      };
      manageCrossAccountAPI
        .deleteBzbsApplication(params)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.closeModalProcess();
            setTimeout(() => {
              this.titleModal =
                this.lbls.applicationaccount_page.modal.title_success;
              this.contentModal =
                this.lbls.applicationaccount_page.modal.content_delete_success;
              // this.openNotificationWithIcon("success");
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostAddData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        cross_account_id: this.$route.query.id,
        buzzebees_application_Id: this.objDataSave,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageCrossAccountAPI
        .postSaveBzbsApplication(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.resultData = res.data.result;
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal =
                  this.lbls.applicationaccount_page.modal.title_success;
                this.contentModal =
                  this.lbls.applicationaccount_page.modal.content_create_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
</style>
