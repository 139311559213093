import { render, staticRenderFns } from "./TableList.vue?vue&type=template&id=5dce06f0&scoped=true"
import script from "./TableList.vue?vue&type=script&lang=js"
export * from "./TableList.vue?vue&type=script&lang=js"
import style0 from "./TableList.vue?vue&type=style&index=0&id=5dce06f0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dce06f0",
  null
  
)

export default component.exports