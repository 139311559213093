<template>
  <div class="table-user-page">
    <div class="d-flex justify-content-end mb-3">
      <a-input-search
        v-model="searchData"
        :placeholder="lbls.user_page.input_search"
        class="input-search"
        size="large"
        @search="onSearch(searchData)"
      >
        <template slot="suffix">
          <a-icon
            v-if="isSearchData"
            type="close-circle"
            theme="filled"
            class="icon-close-search-input"
            @click="closeSearchData()"
          />
        </template>
      </a-input-search>
    </div>

    <a-card v-if="isShowSkeleton">
      <a-skeleton />
    </a-card>
    <div v-else>
      <div class="campaign-list-table">
        <a-table
          id="campaign-list-table"
          row-key="id"
          :columns="columns"
          :data-source="data"
          :scroll="{ y: tableHeight, x: 800 }"
          :pagination="false"
          :locale="{
            filterConfirm: lbls.user_page.btn.ok,
            filterReset: lbls.user_page.btn.reset,
          }"
          :custom-row="customRowTable"
          @change="handleTableChange"
        >
          <div
            slot="filterDropdown"
            slot-scope="{ selectedKeys, confirm, clearFilters, column }"
            style="padding: 8px"
          >
            <a-button
              type="primary"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
              "
            >
              {{ lbls.btn.search }}
            </a-button>
            <a-button
              style="width: 90px"
              @click="() => handleTableReset(clearFilters)"
            >
              {{ lbls.btn.reset }}
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template slot="template-email" slot-scope="text, record">
            <div class="custom-status-text">
              <div>{{ text }}</div>
              <div
                v-if="record.identity_provider"
                class="text-secondary-custom"
              >
                {{ lbls.user_page.tbl_identity_provider
                }}{{ record.identity_provider }}
              </div>
              <div
                v-if="record.is_owner_account"
                class="owner-account"
                style="font-size: 12px"
              >
                <span>
                  <a-icon
                    class="icon"
                    style="vertical-align: 1.5px !important"
                    type="check-circle"
                  />
                </span>
                <span>{{ lbls.user_page.tbl_owner_user }}</span>
              </div>
            </div>
          </template>
          <template slot="template-status" slot-scope="text">
            <a-tag v-if="text" :color="'green'" class="tag-status">
              <a-badge
                :status="'success'"
                :text="lbls.user_page.title_status_active"
              />
            </a-tag>
            <a-tag v-else :color="'red'" class="tag-status">
              <a-badge
                :status="'error'"
                :text="lbls.user_page.title_status_inactive"
              />
            </a-tag>
          </template>
          <template slot="template-name" slot-scope="text, record">
            <div class="custom-status-text">
              <a-tooltip
                placement="topLeft"
                :title="lbls.user_page.tbl_colum_display_name"
              >
                {{ record.display_name }}
              </a-tooltip>
            </div>
            <div class="text-secondary-custom">
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>
                    {{ lbls.user_page.tbl_colum_fullname }}
                  </span>
                </template>
                {{ record.full_name }}
              </a-tooltip>
            </div>
          </template>
          <template slot="template-role" slot-scope="text, record">
            <span v-if="text.length > 0" class="features-custom">
              <div v-for="(tag, index) in text" :key="tag.id">
                <div v-if="index <= checkShowMore(record)">
                  <div class="d-flex align-items-center">
                    <div class="w-100">
                      <a-tooltip
                        placement="top"
                        :title="lbls.role_page.btn.manage"
                      >
                        <router-link
                          :to="{
                            name: supportVersion3
                              ? 'ManageRoleSetPerMission'
                              : 'ManageAssignRole',
                            query: {
                              id: tag.id,
                            },
                          }"
                        >
                          <span v-if="record.roles.length > 1">
                            {{ index + 1 }}.
                          </span>
                          <span>
                            {{ tag.name }}
                          </span>
                        </router-link>
                      </a-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="text.length > 3"
                class="show-hide-custom mt-2"
                @click="loadMore(record)"
              >
                <span>
                  {{
                    record.isShowMore
                      ? lbls.role_page.btn.hide
                      : lbls.role_page.btn.more
                  }}
                </span>
                <span v-if="!record.isShowMore"> ({{ text.length }})</span>
                <span class="ml-2">
                  <a-icon v-if="record.isShowMore" type="caret-up" />
                  <a-icon v-else type="caret-down" />
                </span>
              </div>
            </span>
            <span v-else> - </span>
          </template>
          <template slot="template-edit" slot-scope="text, record">
            <div v-if="record" class="action-setting">
              <a-button
                type="primary"
                ghost
                :disabled="!isPermissionSetting"
                @click="() => handleSetting(record)"
              >
                <a-icon type="setting" />
                {{ lbls.btn.setting }}
              </a-button>
            </div>
          </template>
        </a-table>

        <ShowTotal
          :datashowtitle="data"
          :conditiontable="conditionTable"
          :pagesize="page_size"
          :totallist="totalList"
          @callgetlist="callGetList"
          @setpagesize="setPageSize($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Cache from "@/helper/Cache.js";
import manageUsersAPI from "@/core/Page/ManageUserAPI";
import { config } from "@/config/bzbsconfig.js";
import ShowTotal from "@/components/Layout/ShowTotal";

export default {
  name: "TableUser",
  components: { ShowTotal },
  data() {
    return {
      permission_key: "user",
      searchData: null,
      isSearchData: false,
      nameSearch: null,
      isShowSkeleton: true,
      columns: [
        {
          id: "Status",
          title: "Status",
          width: 135,
          keys: "tbl_colum_status",
          dataIndex: "is_active",
          scopedSlots: { customRender: "template-status" },
          filters: [
            {
              text: "title_status_active",
              value: "true",
            },
            {
              text: "title_status_inactive",
              value: "false",
            },
          ],
          onFilter: (value, record) => {
            value = value == "true";
            return record.is_active === value;
          },
        },
        {
          id: "Name",
          title: "Name",
          keys: "tbl_colum_name",
          dataIndex: "display_name",
          scopedSlots: { customRender: "template-name" },
        },
        {
          id: "Email",
          title: "Email",
          keys: "tbl_colum_email",
          dataIndex: "email",
          width: 400,
          sorter: (a, b) => a.email.localeCompare(b.email),
          scopedSlots: { customRender: "template-email" },
        },
        {
          id: "Role",
          title: "Role",
          keys: "tbl_colum_role",
          dataIndex: "roles",
          scopedSlots: { customRender: "template-role" },
          filters: [],
          onFilter: (value, record) => {
            if (value == "none") {
              return record.roles.length == 0;
            } else {
              let findobj = record.roles.find((o) => o.id === value);
              if (findobj) {
                record.idfind = findobj.id;
              }
              return record.idfind === value;
            }
          },
        },
        {
          width: 150,
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "template-edit" },
        },
      ],
      data: [],
      page: 0,
      page_size: 25,
      totalList: 0,
      tableHeight: 0,
      supportVersion3: config?.businessRule?.support?.version3 ?? true,
      roleFilters: [],
      conditionTable: {},
      isPermissionSetting: false,
      isPermissionEdit: false,
      isPermissionDelete: false,
      innerHeight: 0,
    };
  },
  watch: {
    data() {
      this.setDataFilters();
      this.handlePermission();
    },
    lbls() {
      this.columns = _.cloneDeep(this.columnsStart);
      this.setColumnTable();
      this.title_table_description = this.lbls.user_page.title_table_description
        ? this.lbls.user_page.title_table_description
        : "*Double-click the table row to assign roles.";
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.$nextTick(() => {
      window.addEventListener("resize", this.calTableHeight);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.calTableHeight);
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.setCallGetList().then(() => {
        this.dataStart = this.data;
        this.columnsStart = _.cloneDeep(this.columns);
        this.setColumnTable();
        this.setDataFilters();
      });
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        if (permission_page?.length) {
          this.isPermissionSetting = this.checkArrowPermission(
            "user-setting",
            permission_page
          );
          this.isPermissionEdit = this.checkArrowPermission(
            "user-edit",
            permission_page
          );
          this.isPermissionDelete = this.checkArrowPermission(
            "user-delete",
            permission_page
          );
        }
      }
    },
    setDataFilters() {
      this.roleFilters.push({
        text: "-",
        value: "none",
      });
      _.forEach(this.data, (element) => {
        if (element.roles) {
          _.forEach(element.roles, (item) => {
            this.roleFilters.push({
              text: item.name,
              value: item.id,
            });
          });
        }
      });
      this.roleFilters = [
        ...new Map(this.roleFilters.map((item) => [item.text, item])).values(),
      ];
      let findcolumns = this.columns.find((o) => o.id == "Role");
      if (findcolumns) {
        findcolumns.filters = this.roleFilters;
      }
      this.$forceUpdate();
    },
    setColumnTable() {
      _.forEach(this.columns, (column) => {
        if (column.keys) {
          column.title = this.lbls.user_page[column.keys];
        }
        if (column.filters) {
          _.forEach(column.filters, (filters) => {
            filters.text = this.lbls.user_page[filters.text];
          });
        }
      });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.isSearchData = this.searchData != "";
        this.nameSearch = text != undefined ? text : "";
        this.setCallGetList();
      } else {
        return;
      }
    },
    setCallGetList() {
      return new Promise((resolve) => {
        this.isShowSkeleton = true;
        this.page = 0;
        this.page_size = this.page_size;
        this.data = [];
        this.callGetList().then(() => {
          this.calTableHeight();
          this.tableContent = document.querySelector(".ant-table-body");
          this.onScroll();
          resolve();
        });
      });
    },
    setPageSize(value) {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = value;
      this.data = [];
      this.callGetList();
    },
    callGetList() {
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        page: this.page,
        page_size: this.page_size,
      };
      if (this.nameSearch) {
        params.filter = this.nameSearch;
      }
      return new Promise((resolve, reject) => {
        manageUsersAPI
          .getUserList(params)
          .then((res) => {
            this.totalList = 0;
            if (res.data) {
              if (res.data.result) {
                let item = res.data.result.items;
                let paging = res.data.result.paging;
                if (item.length > 0) {
                  this.data.push(...res.data.result.items);
                  this.data.forEach((elements) => {
                    let first_name = elements.first_name
                      ? elements.first_name + " "
                      : "-";
                    let last_name = elements.last_name
                      ? elements.last_name
                      : "";
                    let display_name = elements.display_name
                      ? elements.display_name
                      : "-";
                    elements.full_name = first_name + last_name;
                    elements.display_name = display_name;
                    elements.isShowMore = false;
                  });
                }
                this.totalList = this.data.length;
                this.conditionTable = {
                  hasMoreData: paging.has_more_data,
                  isFilters: false,
                  nameSearch: this.nameSearch,
                };
              }
            }
            this.$nextTick(() => {
              this.handleLoading(false);
              setTimeout(() => {
                this.isShowSkeleton = false;
                resolve(res.data);
              }, 300);
            });
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            reject(error);
          });
      });
    },
    closeSearchData(searchData) {
      this.searchData = "";
      this.onSearch(searchData);
      this.$forceUpdate();
    },
    customRowTable(record, index) {
      return {
        attrs: {
          id: index,
        },
        on: {
          dblclick: () => {
            this.handleSetting(record);
          },
        },
      };
    },
    handleTableChange(pagination, filters, sorte, currentData) {
      console.log("pagination", pagination);
      console.log("filters", filters);
      console.log("sorte", sorte);
      console.log("currentData", currentData);
    },
    handleTableSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleTableReset(clearFilters) {
      clearFilters();
      this.searchText = "";
      this.valueStatus = "";
      this.data = this.dataStart;
    },
    onScroll() {
      this.tableContent.addEventListener("scroll", (event) => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = event.target.scrollTop;
        let isScrollDown = this.lastscrollTop !== event.target.scrollTop;
        let scrollPersent = (currentScroll / maxScroll) * 100;
        if (
          scrollPersent >= 95 &&
          this.conditionTable.hasMoreData &&
          isScrollDown &&
          !this.isFilters
        ) {
          this.conditionTable.hasMoreData = false;
          this.handleLoading(true);
          this.callGetList();
        } else {
          return;
        }
        this.lastscrollTop = event.target.scrollTop;
      });
    },
    checkShowMore(record) {
      let lengthData = 2;
      if (record.isShowMore) {
        lengthData = record.roles.length;
      }
      return lengthData;
    },
    loadMore(record) {
      record.isShowMore = record.isShowMore == true;
      record.isShowMore = !(record.isShowMore != false);
      this.$forceUpdate();
    },
    handleSetting(record) {
      console.log("handleSetting", record);
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_mixin.scss";
.table-user-page {
  .input-search.ant-input-search {
    width: 350px;
    height: 40px;
    justify-content: end;
  }
  .btn-role {
    width: 50px;
    .ant-divider,
    .ant-divider-vertical {
      top: 0px !important;
      width: 1px !important;
      height: 10px !important;
      margin: 0 3px !important;
    }
  }
  .features-custom {
    .show-hide-custom {
      cursor: pointer;
      font-size: 12px;
      color: $color-primary !important;
      font-weight: 600;
    }
    .img-rotate {
      transform: rotate(180deg);
    }
  }
  .ant-table-filter-dropdown .ant-dropdown-menu {
    max-height: calc(100vh - 300px);
  }
  .action-setting {
    .ant-btn {
      height: 30px;
      padding: 0;
    }
  }
}
</style>
