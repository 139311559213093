<template>
  <div>
    <a-modal
      v-model="modalprocess.visible"
      centered
      class="modal-with-content"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <div>
          <span>{{ modalprocess.title }}</span>
        </div>
      </template>
      <div>
        <a-form :form="form" layout="vertical">
          <div class="config-gallery mb-3" @dragover.prevent @drop.prevent>
            <input
              ref="file"
              type="file"
              class="d-none"
              accept="image/jpeg, image/png"
              multiple
              @change="handleChange"
            />
            <label> {{ lbls.application_page.img_upload.name }} </label>
            <div class="card-image-list">
              <draggable v-model="fileListData" class="draggable-list w-100">
                <div
                  v-for="item in fileListData"
                  :key="item.picture_id"
                  class="card-item"
                >
                  <div class="card-image">
                    <img
                      class="image"
                      :src="
                        item.picture.includes(';base64') ||
                        item.picture.includes('http')
                          ? item.picture
                          : 'data:' +
                            item.file_type +
                            ';base64, ' +
                            item.picture
                      "
                      @error="handleError"
                    />
                    <div v-if="fileListData.length > 0" class="actions">
                      <a type="primary" @click="handleOpenPreview()">
                        <a-icon type="eye" />
                      </a>
                      <a-popconfirm
                        :title="lbls.application_page.modal.content_delete_img"
                        :ok-text="lbls.application_page.modal.btn.delete"
                        :cancel-text="lbls.btn.cancel"
                        @confirm="handledelete(item)"
                      >
                        <a><a-icon type="delete" /></a>
                      </a-popconfirm>
                    </div>
                  </div>
                </div>
                <div
                  v-show="fileListData.length < 1"
                  slot="footer"
                  class="upload-image-btn"
                  @click="clickUpload()"
                  @drop="dragFile"
                >
                  <div>
                    <span class="icon"
                      ><a-icon type="plus" class="fs-30"
                    /></span>
                    <span class="text">Upload</span>
                  </div>
                </div>
              </draggable>
            </div>
            <div class="text-secondary-custom mt-1 text-right">
              {{ lbls.application_page.img_upload.placeholder }}
            </div>
          </div>
          <a-form-item :label="lbls.create_account.package_list.name">
            <a-select
              v-decorator="[
                'packageprefix',
                {
                  initialValue: [],
                  rules: [
                    {
                      required: true,
                      message: lbls.create_account.package_list.required,
                    },
                  ],
                },
              ]"
              show-search
              :placeholder="lbls.create_account.package_list.placeholder"
              @search="(e) => getListPackage(e)"
            >
              <a-select-option
                v-for="(item, index) in optionPackage"
                :key="index"
                :value="[item.package, item.id]"
              >
                {{ item.package }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="lbls.application_page.input_appid.name">
            <a-select
              v-decorator="[
                'applicationprefix',
                {
                  initialValue: [],
                  rules: [
                    {
                      required: true,
                      message: lbls.create_account.application_id.required,
                    },
                  ],
                },
              ]"
              show-search
              :placeholder="lbls.create_account.application_id.placeholder"
              @search="(e) => getListApplication(e)"
            >
              <a-select-option
                v-for="(item, index) in optionApp"
                :key="index"
                :value="[item.id, item.name]"
              >
                {{ item.id }} ({{ item.name }})
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
      <template slot="footer">
        <a-button @click="handleClose">
          {{
            modalprocess.cancelText
              ? modalprocess.cancelText
              : lbls.user_page.btn.cancel
          }}
        </a-button>
        <a-button type="primary" @click="handleSave">
          {{
            modalprocess.okText ? modalprocess.okText : lbls.user_page.btn.save
          }}
        </a-button>
      </template>
    </a-modal>
    <!-- Modal Preview -->
    <a-modal v-model="modal2Visible" class="preview-image" centered footer="">
      <div class="p-3">
        <div v-for="(item, key) in fileListData" :key="key">
          <img
            class="image"
            :src="
              item.picture.includes(';base64') || item.picture.includes('http')
                ? item.picture
                : 'data:' + item.file_type + ';base64, ' + item.picture
            "
          />
        </div>
      </div>
    </a-modal>
    <!-- Modal Preview -->
  </div>
</template>
<script>
import _ from "lodash";
import Cache from "@/helper/Cache.js";
import fileReader from "@/helper/fileReader";
import draggable from "vuedraggable";
import manageCrossAccountAPI from "@/core/Page/ManageCrossAccountAPI";
import ManagePackageAPI from "@/core/Page/ManagePackageAPI";

export default {
  name: "ManageApplication",
  components: { draggable },
  props: {
    modalprocess: {
      type: Object,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      logo: require("@/assets/images/bzbs-logo1.png"),
      disabledok: true,
      objDataSave: {},
      is_duplicate: "",
      helpmsg: "",
      maxLengthAppID: 100,
      totalCharAppID: 0,
      maxLengthName: 100,
      totalCharName: 0,
      maxLengthDescription: 4000,
      totalCharDescription: 0,
      fileReader,
      fileListData: [],
      modal2Visible: false,
      optionApp: [],
      optionPackage: [],
      page: 1,
      pageSize: 100,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.getListPackage(null);
      this.getListApplication(null);
    },
    getListPackage(name) {
      console.log("getListPackage : ", name);
      this.optionPackage = [];
      return new Promise((resolve, reject) => {
        let params = {
          account_id: this.exAccount.account.id,
          page: this.page,
          page_size: this.page_size,
        };
        if (name) params.package_name = name;
        ManagePackageAPI.getSearchPackageList(params)
          .then((res) => {
            if (res?.data?.result?.items.length > 0) {
              this.optionPackage = res.data.result.items;
            }
            this.$nextTick(() => {
              this.$forceUpdate();
              resolve(res);
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getListApplication(name) {
      console.log("getListApplication : ", name);
      this.optionApp = [];
      return new Promise((resolve, reject) => {
        let params = {
          page: this.page,
          page_size: this.pageSize,
        };
        if (name) params.search = name;
        manageCrossAccountAPI
          .getBuzzebeesApplication(params)
          .then((res) => {
            if (res?.data?.result?.items.length > 0) {
              this.optionApp = res.data.result.items;
            }
            this.$nextTick(() => {
              this.$forceUpdate();
              resolve(res);
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    handleClose() {
      this.modalprocess.visible = false;
      this.form.resetFields();
      this.$emit("actionModal", "close");
    },
    handleSave(e) {
      this.objDataSave = [];
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.idModal = "confirm";
          this.objDataSave.buzzebees_application_Id =
            values.applicationprefix[0];
          this.objDataSave.name = values.applicationprefix[1];
          this.objDataSave.description = values.applicationprefix[1];
          this.objDataSave.package_id = values.packageprefix[1];
          if (this.fileListData.length > 0) {
            this.objDataSave.application_logo =
              this.fileListData[0].picture_file;
          }
          this.$emit("actionModal", this.objDataSave);
        }
      });
    },
    handleChange(event) {
      _.forEach(event.target.files, (item) => {
        if (this.checkValidate(item)) {
          fileReader.getBase64(item).then((imageUrl) => {
            this.fileListData.push({
              picture: imageUrl,
              picture_file: item,
            });
          });
        }
      });
    },
    checkValidate(file) {
      const isImg = file.type === "image/jpeg" || file.type === "image/png";
      if (!isImg) {
        this.$error({
          title: this.lbls.application_page.modal_error.title_error,
          content: this.lbls.application_page.modal_error.wrong_type,
          centered: true,
          okText: this.lbls.application_page.modal_error.btn.ok,
          okType: "default",
        });
      }
      const isSize = file.size < 500 * 1024;
      if (!isSize) {
        this.$error({
          title: this.lbls.application_page.modal_error.title_error,
          content: this.lbls.application_page.modal_error.more_than,
          centered: true,
          okText: this.lbls.application_page.modal_error.btn.ok,
          okType: "default",
        });
      }
      return isImg && isSize;
    },
    dragFile(e) {
      _.forEach(e.dataTransfer.files, (item) => {
        if (this.checkValidate(item)) {
          fileReader.getBase64(item).then((imageUrl) => {
            this.fileListData.push({
              picture: imageUrl,
              picture_file: item,
            });
          });
        }
      });
    },
    clickUpload() {
      let fileInputElement = this.$refs.file;
      if (fileInputElement.value != "") {
        fileInputElement.value = "";
      }
      fileInputElement.click();
    },
    handleOpenPreview() {
      this.modal2Visible = true;
    },
    handledelete() {
      this.fileListData = [];
    },
    handleError(event) {
      event.target.src = "";
      this.fileListData = [];
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
</style>
