<template>
  <div>
    <AppLayout>
      <Breadcrumbs
        v-if="lbls && lbls.application_page"
        :databreadcrumbs="breadcrumbs"
        :title="lbls.application_page.title_page"
        :is-action-flex="true"
        :description="lbls.application_page.title_table_description"
      >
        <template v-slot:actions>
          <a-input-search
            v-model="searchData"
            :placeholder="lbls.application_page.input_search"
            class="input-search"
            size="large"
            @search="onSearch(searchData)"
          >
            <template slot="suffix">
              <a-icon
                v-if="isSearchData"
                type="close-circle"
                theme="filled"
                class="icon-close-search-input"
                @click="closeSearchData()"
              />
            </template>
          </a-input-search>
          <a-button
            type="primary"
            :disabled="!isPermissionCreate"
            @click="handleCreate()"
          >
            <a-icon type="plus-circle" />
            {{ lbls.application_page.drw_title_create }}
          </a-button>
        </template>
      </Breadcrumbs>
      <a-card v-if="isShowSkeleton">
        <a-skeleton />
      </a-card>
      <template v-else>
        <!-- List -->
        <div v-if="showStyle == 'list'" class="roleContainer">
          <AntList :datalist="data" @handleedit="handleEdit($event)"> </AntList>
        </div>
        <!-- Table -->
        <div v-if="showStyle == 'table'">
          <TableList
            :datatable="data"
            :table-height="tableHeight"
            :conditiontable="conditionTable"
            @callgetlist="callGetList"
            @onsearchtable="onSearchTable($event)"
            @handleedit="handleEdit($event)"
            @handlechangepackage="handleChangePackage($event)"
          />
        </div>
        <ShowTotal
          :datashowtitle="data"
          :conditiontable="conditionTable"
          :pagesize="page_size"
          :totallist="totalList"
          @callgetlist="callGetList"
          @setpagesize="setPageSize($event)"
        />
      </template>
      <!-- Modal -->
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          :dataobjselect="dataObjSelect"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <!-- Modal -->
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
      <!-- Modal -->
      <div v-if="isModalChangePackage">
        <ModalChangePackage
          :conditionmodal="conditionChangePackage"
          :data="dataCurrentPackage"
          @actionModal="actionModalChangePackage($event)"
        >
        </ModalChangePackage>
      </div>
      <!-- Modal -->
      <div v-if="isModalCreate">
        <ModalAddApplication
          :modalprocess="modalprocess"
          @actionModal="actionModalCreate($event)"
        >
        </ModalAddApplication>
      </div>
    </AppLayout>
  </div>
</template>
<script>
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import Cache from "@/helper/Cache.js";
import ManageApplicationAPI from "@/core/Page/ManageApplicationAPI";
import managePackageAPI from "@/core/Page/ManagePackageAPI";
import ShowTotal from "@/components/Layout/ShowTotal";
import TableList from "@/module/Application/ManageApplication/components/Table/TableList";
import AntList from "@/module/Application/ManageApplication/components/List/Lists";
import ModalProcess from "@/module/Application/ManageApplication/components/Modal/ModalProcess.vue";
import ModalChangePackage from "@/module/Application/ManageApplication/components/Modal/ModalChangePackage.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";
import ModalAddApplication from "@/module/Application/ManageApplication/components/Modal/AddApplication.vue";

export default {
  name: "ManageApplication",
  components: {
    AppLayout,
    ShowTotal,
    TableList,
    ModalProcess,
    AntList,
    ModalAlert,
    ModalChangePackage,
    ModalAddApplication,
  },
  data() {
    return {
      searchData: "",
      isSearchData: false,
      permission_key: "application",
      action_key: "view",
      isShowSkeleton: true,
      data: [],
      breadcrumbs: [],
      totalList: 0,
      nameSearch: "",
      isModalResponse: false,
      idModal: "",
      contentModal: "",
      titleModal: "",
      titleModalProcess: "",
      isModalProcess: false,
      isCreate: false,
      isEdit: false,
      showStyle: "table",
      isShowSelect: true,
      page: 0,
      page_size: 25,
      conditionTable: {},
      alertData: {},
      dataObjSelect: {},
      modalprocess: {
        visible: false,
        title: "",
        key: "",
        message: "",
        okText: "",
        cancelText: "",
        isCreate: false,
        isEdit: false,
      },
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      isModalChangePackage: false,
      dataCurrentPackage: {},
      dataSelectApp: {},
      conditionChangePackage: {},
      tableHeight: 0,
      isPermissionCreate: true,
      isDelete: false,
      checkPackage: "",
      isModalCreate: false,
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.calTableHeight);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.calTableHeight);
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.setCallGetList().then(() => {
        this.calTableHeight();
      });
      if (this.$store.state.menu?.length) {
        this.handlePermissionPage(this.permission_key, this.action_key);
        let permission_page = this.handlePermissionPage(this.permission_key);
        this.isPermissionCreate =
          permission_page.includes("application-create");
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.application,
        name: "ManageApplication",
      });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.isSearchData = this.searchData != "";
        this.nameSearch = text != undefined ? text : "";
        this.setCallGetList();
      } else {
        return;
      }
    },
    closeSearchData(searchData) {
      this.searchData = "";
      this.onSearch(searchData);
      this.$forceUpdate();
    },
    onSearchTable(data) {
      this.totalList = data.totalList >= 0 ? data.totalList : this.totalList;
      this.conditionTable.isFilters = data.isFilters;
    },
    handleCreate() {
      this.resetCondition();
      this.handleLoading(true);
      this.titleModalProcess = this.lbls.application_page.drw_title_create;
      this.dataObjSelect = {};
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
      };
      this.isModalCreate = true;
      this.handleLoading(false);
    },
    handleEdit(record) {
      this.resetCondition();
      this.handleLoading(true);
      this.titleModalProcess = this.lbls.application_page.drw_title_edit;
      this.isEdit = true;
      this.callGetDetail(record);
    },
    handleChangePackage(record) {
      console.log("handleChangePackage : ", record);
      this.dataCurrentPackage = {};
      this.dataSelectApp = {};
      this.handleLoading(true);
      this.callGetCurrentPackage(record).then((res) => {
        console.log("callGetCurrentPackage : ", res.result);
        if (res.result) {
          this.dataSelectApp = record;
          this.isModalChangePackage = true;
          this.conditionChangePackage = {
            visible: this.isModalChangePackage,
            title: this.lbls.application_page.btn.change_package,
            application_name: record.application.name,
          };
          this.dataCurrentPackage = res.result;
        }
      });
    },
    callGetCurrentPackage(record) {
      let params = {
        account_id: this.exAccount.account.id,
        application_id: record.id,
      };
      return new Promise((resolve) => {
        managePackageAPI
          .getCurrentPackage(params)
          .then((res) => {
            this.handleLoading(false);
            resolve(res.data);
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(false);
            this.errorApi(error);
          });
      });
    },
    actionModalChangePackage(value) {
      if (value == "close") {
        setTimeout(() => {
          this.isModalChangePackage = false;
          return;
        }, 500);
      } else {
        this.callpostChangePackage(value);
      }
    },
    handlemodalOk() {
      if (this.idModal == "error") return;
      this.handleLoading(true);
      this.callPostEditData();
      this.isModalResponse = false;
    },
    actionModal(value) {
      if (value == "close") {
        setTimeout(() => {
          this.isModalProcess = false;
          return;
        }, 500);
      } else {
        this.handleLoading(true);
        if (this.isEdit) {
          this.objDataSave = value;
          this.callPostEditData();
        }
      }
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      this.setCallGetList();
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      this.setCallGetList();
    },
    showModalProcess() {
      this.handleLoading(false);
      this.isModalProcess = true;
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    actionModalCreate(value) {
      if (value == "close") {
        setTimeout(() => {
          this.isModalCreate = false;
          return;
        }, 500);
      } else {
        this.handleLoading(true);
        this.postAddApplication(value);
      }
    },
    closeModalCreate() {
      this.isModalCreate = false;
    },
    resetCondition() {
      this.isCreate = false;
      this.isEdit = false;
      this.isDelete = false;
      this.checkPackage = "";
    },
    setCallGetList() {
      return new Promise((resolve) => {
        this.isShowSkeleton = true;
        this.page = 0;
        this.page_size = this.page_size;
        this.data = [];
        this.callGetList().then(() => {
          resolve();
        });
      });
    },
    setPageSize(value) {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = value;
      this.data = [];
      this.callGetList();
    },
    callGetList() {
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        page: this.page,
        page_size: this.page_size,
      };
      if (this.nameSearch) {
        params.application_name = this.nameSearch;
      }
      return new Promise((resolve, reject) => {
        ManageApplicationAPI.getSearch(params)
          .then((res) => {
            if (res.data?.result) {
              let item = res.data.result.items;
              let paging = res.data.result.paging;
              if (item.length > 0) {
                this.data.push(...res.data.result.items);
                if (this.data.length > 0) {
                  this.data.forEach((elements) => {
                    elements.id = elements.application.id;
                  });
                  this.isShowSelect = true;
                } else {
                  this.isShowSelect = false;
                  this.showStyle = "table";
                }
              }
              this.totalList = this.data.length;
              this.conditionTable = {
                hasMoreData: paging.has_more_data,
                isFilters: false,
              };
            }
            this.$nextTick(() => {
              this.handleLoading(false);
              this.isShowSkeleton = false;
              resolve(res.data);
            });
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            reject(error);
          });
      });
    },
    callPostEditData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        application_id: this.objDataSave.id,
        application_description: this.objDataSave.description,
        application_is_active: this.objDataSave.is_active,
        buzzebees_application_Id: this.objDataSave.buzzebees_application_Id,
      };
      this.objForSave = JSON.stringify(valueSave);
      ManageApplicationAPI.postEditApplication(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              if (this.objDataSave.application_logo) {
                this.callPostUpdateLogo();
              }
              this.idModal = "success";
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal =
                  this.lbls.application_page.modal.title_success;
                this.contentModal =
                  this.lbls.application_page.modal.content_update_success;
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostUpdateLogo() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        application_id: this.objDataSave.id,
        application_logo: this.objDataSave.application_logo,
      };
      ManageApplicationAPI.postUpdateLogo(valueSave)
        .then((res) => {
          if (res.status == "200") {
            console.log(res.data.result);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callpostChangePackage(package_id) {
      var valueSave = {
        account_id: this.exAccount.account.id,
        application_id: this.dataSelectApp.id,
        package_id: package_id,
      };
      this.objForSave = JSON.stringify(valueSave);
      ManageApplicationAPI.postChangePackage(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.isModalChangePackage = false;
              setTimeout(() => {
                this.titleModal =
                  this.lbls.application_page.modal.title_success;
                this.contentModal =
                  this.lbls.application_page.modal.content_update_success;
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetDetail(record) {
      let params = {
        account_id: this.exAccount.account.id,
        application_id: record.id,
      };
      ManageApplicationAPI.getApplicationDetail(params)
        .then((res) => {
          if (res.data) {
            if (res.data.result) {
              let result = res.data.result;
              const newData = {
                id: result.id,
                name: result.name,
                description: result.description,
                buzzebees_application_Id: result.buzzebees_application_Id,
                is_active: result.is_active,
              };
              if (record.package) {
                newData.package = record.package.name;
                this.checkPackage = record.package.name;
              }
              if (result.logo_url) {
                newData.logo_url = result.logo_url;
              }
              this.dataObjSelect = newData;
              this.modalprocess = {
                visible: true,
                title: this.titleModalProcess,
                key: "application_page.",
                isCreate: this.isCreate,
                isEdit: this.isEdit,
              };
              this.showModalProcess();
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    postAddApplication(value) {
      var valueSave = {
        account_id: this.exAccount.account.id,
        package_id: value.package_id,
        application_name: value.name,
        application_description: value.description,
        buzzebees_application_Id: value.buzzebees_application_Id,
      };
      if (value.application_logo) {
        valueSave.application_logo = value.application_logo;
      }
      ManageApplicationAPI.postCreateApplicationLogo(valueSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.isShowSkeleton = true;
              this.closeModalCreate();
              setTimeout(() => {
                this.titleModal =
                  this.lbls.application_page.modal.title_success;
                this.contentModal =
                  this.lbls.application_page.modal.content_create_success;
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
</style>
