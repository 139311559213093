var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AppLayout',[_c('div',{staticClass:"campaigns-detail-main campaigns-detail-page page",class:`${_vm.$route.name}`},[_c('Breadcrumbs',{attrs:{"is-action-flex":true,"databreadcrumbs":_vm.breadcrumbs,"title":_vm.dataPageBreadcrumb.name},scopedSlots:_vm._u([(!_vm.isShowSkeleton)?{key:"actions",fn:function(){return [_c('div',{staticClass:"action-list"},[_c('a-tooltip',{attrs:{"placement":"topLeft"}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.lbls.role_page.btn.assign_applications_to_role)+" ")]),_c('router-link',{attrs:{"to":{
                  name: 'ManageAssignApplicationstoRole',
                  query: {
                    id: _vm.$route.query.id,
                  },
                }}},[_c('a-icon',{attrs:{"type":"plus-square"}})],1)],2),_c('a-tooltip',{attrs:{"placement":"topLeft"}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.lbls.role_page.btn.manage)+" ")]),_c('router-link',{attrs:{"to":{
                  name: 'ManageRoleSetPerMission',
                  query: {
                    id: _vm.$route.query.id,
                  },
                }}},[_c('a-icon',{attrs:{"type":"setting"}})],1)],2)],1)]},proxy:true}:null],null,true)}),(_vm.isShowSkeleton)?_c('a-card',[_c('a-skeleton')],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShowSkeleton),expression:"!isShowSkeleton"}],staticClass:"manage-role-assigns"},[_c('a-row',{staticClass:"flex-center"},[_c('a-col',{attrs:{"sm":24,"md":11,"lg":11}},[_c('TableList',{attrs:{"datatable":_vm.dataAvailableUser,"dataselect":_vm.idUserForAdd,"databtn":'add',"record":_vm.record},on:{"onsearch":function($event){return _vm.onSearchAdd($event)},"checkboxall":function($event){return _vm.onChangesCheckboxAddAll($event)},"setdataforsave":function($event){return _vm.setDataForSave($event)},"callsaveuser":function($event){return _vm.handleAddUserInRole($event)}}})],1),_c('a-col',{staticClass:"custom-btn",attrs:{"sm":4,"md":2,"lg":2}},[_c('div',{staticClass:"text-center"},[_c('div',[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.lbls.role_page.btn.add_user_select)+" ")]),_c('a-button',{staticClass:"vertical-initial",attrs:{"disabled":_vm.idUserForAdd.length == 0,"type":"primary"},on:{"click":function($event){return _vm.handleAddUserInRoles()}}},[_c('a-icon',{attrs:{"type":"right"}})],1)],2)],1),_c('div',{staticClass:"mt-2"},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.lbls.role_page.btn.delete_user_select)+" ")]),_c('a-button',{staticClass:"vertical-initial",class:{
                      'btn-custom-delete': _vm.idUserForDelete.length > 0,
                    },attrs:{"disabled":_vm.idUserForDelete.length == 0,"type":"primary"},on:{"click":function($event){return _vm.handleDeleteUserInRoles()}}},[_c('a-icon',{attrs:{"type":"left"}})],1)],2)],1)])]),_c('a-col',{attrs:{"sm":24,"md":11,"lg":11}},[_c('TableList',{attrs:{"datatable":_vm.dataUserList,"dataselect":_vm.idUserForDelete,"databtn":'delete',"record":_vm.record},on:{"onsearch":function($event){return _vm.onSearchDelete($event)},"checkboxall":function($event){return _vm.onChangesCheckboxDeleteAll($event)},"setdataforsave":function($event){return _vm.setDataForSave($event)},"callsaveuser":function($event){return _vm.handleDeleteUserInRole($event)}}})],1)],1)],1),(_vm.isModalResponse)?_c('div',[_c('ModalAlert',{attrs:{"modalalert":_vm.modalAlert,"alertdata":_vm.alertData},on:{"handlemodalok":_vm.handlemodalOk}})],1):_vm._e()],1)]),_c('div',{staticClass:"custom-footer-setpermission"},[_c('a-button',{attrs:{"type":"primary","disabled":_vm.countUserList == 0 && _vm.dataDelete.length == 0},on:{"click":function($event){return _vm.handleSave()}}},[_vm._v(" "+_vm._s(_vm.lbls.role_page.btn.save)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }