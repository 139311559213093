import Vue from "vue";
import Router from "vue-router";

import LoginInvite from "@/module/Home/LoginInvite";
import Login from "@/module/Home/Login.vue";
import Logout from "@/module/Home/Logout.vue";
import Redirectms from "@/module/Home/Redirectms";
import AuthUnauthorized from "@/module/Home/AuthUnauthorized";
import CreateAccount from "@/module/Home/CreateAccount";
import Default from "@/module/Home/Default";
import PageNotFound from "@/module/PageNotFound/pages/Index";
import ErrorConfig from "@/module/PageNotFound/pages/errorConfig";

import ManageRoles from "@/module/Role/ManageRole/page/ManageRoles";
import ManageRoleSetRoles from "@/module/Role/ManageSetRole/page/ManageRoleSetRoles";
import ManageRoleSetRolestest from "@/module/Role/ManageSetRole/page/ManageRoleSetRole-test";
import ManageSetRole from "@/module/Role/ManageSetRole/page/ManageSetRole";
import ManageSetRoles from "@/module/Role/ManageSetRole/page/ManageSetRoles";
import ManagePermission from "@/module/Role/ManageSetRole/page/ManageSetPerMissionofRoles";
import ManagePermissionApp from "@/module/Role/ManageSetRole/page/ManageSetPerMissionofApp";
import ManageSetPerMissionofAppb from "@/module/Role/ManageSetRole/page/ManageSetPerMissionofAppb";
import ManageSetPerMissionTest from "@/module/Role/ManageSetRole/page/ManageSetPerMissionofApp-Test";
import ManageSetPerMissionNew from "@/module/Role/ManageSetRole/page/ManageSetPerMissionofAppNew";
import ManageSetPerMissionApp from "@/module/Role/ManageSetRole/page/ManageSetPerMissioninApp";
import SetPerMission from "@/module/Role/ManageSetRole/page/SetPerMission";
import ManageAssigntoRole from "@/module/Role/ManageAssigntoRole/page/ManageAssigntoRole";
import ManageAssignApplicationstoRole from "@/module/Role/ManageAssigntoRole/page/ManageAssignApplicationstoRole";

import ManagePermissioNew from "@/module/Role/ManageSetRole/page/ManageSetPerMissionofRolesNew";
import ManageAssignRole from "@/module/Role/ManageAssignRole/page/manageAssignRole";

import CreateApplications from "@/module/Application/CreateApplications";
import EditApplications from "@/module/Application/EditApplications";
import SelectPackage from "@/module/Application/SelectPackage/page/SelectPackage";
import ComparePackage from "@/module/Application/ComparePackage/page/ComparePackage";
import CompareRole from "@/module/Role/CompareRole/page/CompareRole";

import CheckPermission from "@/module/Home/CheckPermission";
import ManageApplication from "@/module/Application/ManageApplication/page/ManageApplication";

import ManageCrossAccount from "@/module/Cross/ManageCrossAccount/page/ManageCrossAccount";
import ManageCrossApplication from "@/module/Cross/ManageCrossApplication/page/ManageCrossApplication";
import CrossAccountDetail from "@/module/Cross/ManageCrossAccountDetail/page/CrossAccountDetail";
import ApplicationAccount from "@/module/Cross/ManageApplicationAccount/page/ApplicationAccount";
import TransferOwnerAccount from "@/module/Cross/ManageTransferOwnerAccount/page/ManageTransferOwnerAccount.vue";
import InvitationAccount from "@/module/Cross/ManageInvitationAccount/page/InvitationAccount";
import CreateOwnerAccount from "@/module/Cross/ManageCreateOwnerAccount/page/CreateOwnerAccount";

//Package
import ManagePackage from "@/module/Package/page/ManagePackage";
import BuyPackage from "@/module/Package/page/BuyPackage";

//User
import Manage from "@/module/User/ManageUsers/page/Manage"
import ManageUsers from "@/module/User/ManageUsers/page/ManageUsers";
import ManageUsersTest from "@/module/User/ManageUsers/page/ManageUsers-old";
import InviteUsersDetail from "@/module/User/InviteUsers/page/InviteUsersDetail";
import InviteUsersMail from "@/module/User/InviteUsersMail/page/InviteUsersMail";
import InviteMember from "@/module/User/InviteMember/page/Invite";

Vue.use(Router);
export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Login",
      component: Login,
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout,
    },
    {
      path: "/unauthorized",
      name: "unauthorized",
      component: AuthUnauthorized,
    },
    {
      path: "/logininvite",
      name: "LoginInvite",
      component: LoginInvite,
    },
    {
      path: "/redirectms",
      name: "Redirectms",
      component: Redirectms,
    },
    {
      path: "/createaccount",
      name: "CreateAccount",
      component: CreateAccount,
    },
    {
      path: "/default",
      name: "Default",
      component: Default,
    },
    {
      path: "/manageroles",
      name: "ManageRoles",
      component: ManageRoles,
    },
    {
      path: "/managerolesetroles",
      name: "ManageRoleSetRoles",
      component: ManageRoleSetRoles,
    },
    {
      path: "/managerolesetrolestest",
      name: "ManageRoleSetRolestest",
      component: ManageRoleSetRolestest,
    },
    {
      path: "/managesetrole",
      name: "ManageSetRole",
      component: ManageSetRole,
    },
    {
      path: "/managesetroles",
      name: "ManageSetRoles",
      component: ManageSetRoles,
    },
    {
      path: "/setpermission-old",
      name: "ManagePermissionOld",
      component: ManagePermission,
    },
    {
      path: "/setpermissionapp",
      name: "ManagePermissionApp",
      component: ManagePermissionApp,
    },
    {
      path: "/setpermissionapptestb",
      name: "ManageSetPerMissionofAppb",
      component: ManageSetPerMissionofAppb,
    },
    {
      path: "/set-menu-applications",
      name: "ManageSetPerMissionTest",
      component: ManageSetPerMissionTest,
    },
    {
      path: "/set-permission",
      name: "ManageSetPerMissionApp",
      component: ManageSetPerMissionApp,
    },
    {
      path: "/manageroles/set-role-permission",
      name: "ManageRoleSetPerMission",
      component: SetPerMission,
    },
    {
      path: "/manageroles/set-excould-permission",
      name: "ManageSetExcouldPerMission",
      component: SetPerMission,
    },
    {
      path: "/manageroles/set-all-excould-permission",
      name: "ManageSetAllExcouldPerMission",
      component: SetPerMission,
    },
    {
      path: "/manageroles/manageassigntorole",
      name: "ManageAssigntoRole",
      component: ManageAssigntoRole,
    },
    {
      path: "/manageroles/manageassign-applications-to-role",
      name: "ManageAssignApplicationstoRole",
      component: ManageAssignApplicationstoRole,
    },
    {
      path: "/manageroles",
      name: "ManageUsersTest",
      component: ManageUsersTest,
    },
    {
      path: "/manageusers/inviteusersdetail",
      name: "InviteUsersDetail",
      component: InviteUsersDetail,
    },
    {
      path: "/manageusers/inviteusers",
      name: "InviteUsersMail",
      component: InviteUsersMail,
    },
    {
      path: "/managecrossaccount",
      name: "ManageCrossAccount",
      component: ManageCrossAccount,
    },
    {
      path: "/managecrossaccount/cross-application",
      name: "Cross-Application",
      component: ManageCrossApplication,
    },
    {
      path: "/managecrossaccount/crossaccountdetail",
      name: "CrossAccountDetail",
      component: CrossAccountDetail,
    },
    {
      path: "/managecrossaccount/createowneraccount",
      name: "CreateOwnerAccount",
      component: CreateOwnerAccount,
    },
    {
      path: "/managecrossaccount/applicationaccount",
      name: "ApplicationAccount",
      component: ApplicationAccount,
    },
    {
      path: "/managecrossaccount/transfer-owner-account",
      name: "TransferOwnerAccount",
      component: TransferOwnerAccount,
    },
    {
      path: "/manageapplication",
      name: "ManageApplication",
      component: ManageApplication,
    },
    {
      path: "/manageapplication/createapplications",
      name: "CreateApplications",
      component: CreateApplications,
    },
    {
      path: "/manageapplication/editapplications",
      name: "EditApplications",
      component: EditApplications,
    },
    {
      path: "/manageapplication/selectpackage",
      name: "SelectPackage",
      component: SelectPackage,
    },
    {
      path: "/comparepackage",
      name: "ComparePackage",
      component: ComparePackage,
    },
    {
      path: "/comparerole",
      name: "CompareRole",
      component: CompareRole,
    },
    {
      path: "/checkpermission",
      name: "CheckPermission",
      component: CheckPermission,
    },
    {
      path: "/errorconfig",
      name: "errorconfig",
      component: ErrorConfig,
    },
    {
      path: "/setpermission",
      name: "ManagePermission",
      component: ManagePermissioNew,
    },
    {
      path: "/manageroles/manage-assign-role",
      name: "ManageAssignRole",
      component: ManageAssignRole,
    },
    {
      path: "/manageusers",
      name: "ManageUsers",
      component: ManageUsers,
    },
    {
      path: "/manageroles/set-menu-application",
      name: "ManageSetPerMission",
      component: ManageSetPerMissionNew,
    },
    {
      path: "/packages",
      name: "ManagePackage",
      component: ManagePackage,
    },
    {
      path: "/packages/buy-packages",
      name: "BuyPackage",
      component: BuyPackage,
    },
    {
      path: "/managecrossaccount/invite-account",
      name: "InvitationAccount",
      component: InvitationAccount,
    },
    {
      path: "/manage",
      name: "Manage",
      component: Manage,
    },
    {
      path: "/invite-member",
      name: "InviteMember",
      component: InviteMember,
    },
    { path: "/:pathMatch(.*)*", component: PageNotFound },
  ],
});
