<template>
  <AppLayout>
    <div
      :class="`${$route.name}`"
      class="campaigns-detail-main campaigns-detail-page page"
    >
      <Breadcrumbs
        v-if="lbls && lbls.role_page"
        :databreadcrumbs="breadcrumbs"
        :title="lbls.role_page.title_page"
        :is-action-flex="true"
        :description="lbls.role_page.title_table_description"
      >
        <template v-slot:actions>
          <a-input-search
            v-model="searchData"
            :placeholder="lbls.package_page.input_search"
            class="input-search"
            size="large"
            @search="onSearch(searchData)"
          >
            <template slot="suffix">
              <a-icon
                v-if="isSearchData"
                type="close-circle"
                theme="filled"
                class="icon-close-search-input"
                @click="closeSearchData()"
              />
            </template>
          </a-input-search>
          <a-button
            :disabled="!isPermissionCreate"
            type="primary"
            @click="handleCreate"
          >
            <a-icon type="plus-circle" />
            {{ lbls.role_page.btn.add }}
          </a-button>
        </template>
      </Breadcrumbs>
      <a-card v-if="isShowSkeleton">
        <a-skeleton />
      </a-card>
      <div v-else>
        <TableList
          :datatable="data"
          :table-height="tableHeight"
          :conditiontable="conditionTable"
          @onsearchtable="onSearchTable($event)"
          @callgetlist="callGetList"
          @handleedit="handleEdit($event)"
          @handlecopy="handleCopy($event)"
          @handlecopynew="handleCopyNew($event)"
        />
        <ShowTotal
          :datashowtitle="data"
          :conditiontable="conditionTable"
          :pagesize="page_size"
          :totallist="totalList"
          @callgetlist="callGetList"
          @setpagesize="setPageSize($event)"
        />
      </div>
      <!-- Modal -->
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          :dataobjselect="dataObjSelect"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import Cache from "@/helper/Cache.js";
// import PageHeader from "@/module/Role/ManageRole/components/Layout/PageHeader.vue";
import ShowTotal from "@/components/Layout/ShowTotal";
import TableList from "@/module/Role/ManageRole/components/Table/TableList";
import ModalProcess from "@/module/Role/ManageRole/components/Modal/ModalProcess.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";
import managePermissionAPI from "@/core/Page/ManagePermissionAPI";

export default {
  name: "ManageRole",
  components: {
    AppLayout,
    ShowTotal,
    // PageHeader,
    TableList,
    ModalProcess,
    ModalAlert,
  },
  data() {
    return {
      searchData: "",
      isSearchData: false,
      isPermissionCreate: true,
      permission_key: "role",
      action_key: "view",
      breadcrumbs: [],
      data: {},
      objSelect: [],
      isShowSkeleton: true,
      isCreate: false,
      isEdit: false,
      isCopy: false,
      isCopyNew: false,
      isDelete: false,
      isModalProcess: false,
      isModalResponse: false,
      titleModalProcess: "",
      totalList: 0,
      idModal: "",
      titleModal: "",
      contentModal: "",
      nameSearch: "",
      page: 0,
      page_size: 25,
      conditionTable: {},
      dataObjSelect: {},
      modalprocess: {
        visible: false,
        title: "",
        key: "",
        message: "",
        okText: "",
        cancelText: "",
        isCreate: false,
        isEdit: false,
        isCopy: false,
        isCopyNew: false,
      },
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      tableHeight: 0,
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermission();
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.calTableHeight);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.calTableHeight);
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.handlePermission();
      this.setCallGetList().then(() => {
        this.calTableHeight();
      });
      if (this.$store.state.menu?.length) {
        this.handlePermissionPage(this.permission_key, this.action_key);
      }
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        if (permission_page?.length) {
          this.isPermissionCreate = this.checkArrowPermission(
            "role-create",
            permission_page
          );
        }
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.rolemanagement,
        name: "ManageRoles",
      });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.isSearchData = this.searchData != "";
        this.nameSearch = text != undefined ? text : "";
        this.setCallGetList();
      } else {
        return;
      }
    },
    onSearchTable(data) {
      this.totalList = data.totalList >= 0 ? data.totalList : this.totalList;
      this.conditionTable.isFilters = data.isFilters;
    },
    closeSearchData(searchData) {
      this.searchData = "";
      this.onSearch(searchData);
      this.$forceUpdate();
    },
    handleCreate() {
      this.resetCondition();
      this.handleLoading(true);
      this.isCreate = true;
      this.titleModalProcess = this.lbls.role_page.drw_title_create;
      this.showModalProcess();
    },
    handleEdit(record) {
      this.resetCondition();
      this.handleLoading(true);
      this.isEdit = true;
      this.objRecord = record;
      this.titleModalProcess = this.lbls.role_page.drw_title_edit;
      this.callGetDetail();
    },
    handleCopy(record) {
      this.resetCondition();
      this.handleLoading(true);
      this.isCopy = true;
      this.objRecord = record;
      this.titleModalProcess = this.lbls.role_page.drw_title_copy;
      this.callGetDetail();
    },
    handleCopyNew(record) {
      this.resetCondition();
      this.handleLoading(true);
      this.isCopy = true;
      this.isCopyNew = true;
      this.objRecord = record;
      this.titleModalProcess = this.lbls.role_page.drw_title_copy;
      this.callGetDetail();
    },
    handlemodalOk() {
      if (this.idModal == "error") {
        this.isModalResponse = false;
        return;
      }
      this.handleLoading(true);
      if (this.isCreate) {
        this.callPostAddData();
      } else {
        this.callPostEditData();
      }
      this.isModalResponse = false;
    },
    setCallGetList() {
      return new Promise((resolve) => {
        this.isShowSkeleton = true;
        this.page = 0;
        this.page_size = this.page_size;
        this.data = [];
        this.callGetList().then(() => {
          resolve();
        });
      });
    },
    setPageSize(value) {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = value;
      this.data = [];
      this.callGetList();
    },
    actionModal(value) {
      if (value == "close") {
        setTimeout(() => {
          this.isModalProcess = false;
          return;
        }, 500);
      } else {
        this.handleLoading(true);
        if (this.isCreate) {
          this.objDataSave = value;
          this.callPostAddData();
        } else if (this.isEdit) {
          this.objDataSave = value;
          this.callPostEditData();
        } else if (this.isCopy) {
          this.objDataSave = value;
          if (this.isCopyNew) {
            this.callPostCopyDataNew();
          } else {
            this.callPostCopyData();
          }
        }
      }
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      if (this.isCreate) {
        this.$router.push({
          name: "ManagePermission",
          query: { id: this.resultData.id },
        });
      }
      this.setCallGetList();
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      if (this.isCreate) {
        setTimeout(() => {
          this.$router.push({
            name: "ManageAssignApplicationstoRole",
            query: { id: this.resultData.id },
          });
        }, 1000);
      }
      this.setCallGetList();
    },
    showModalAlert() {
      this.modalAlert = {
        id: this.idModal,
        visible: true,
        title: this.titleModal,
        message: this.contentModal,
        okText: this.modalbtnok,
        cancelText: this.modalbtncancel,
        isDelete: this.isDelete,
      };
      this.isModalResponse = true;
    },
    showModalProcess() {
      this.isModalProcess = true;
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        namerecord: this.objRecord.name,
        key: "user_page.",
        isCreate: this.isCreate,
        isEdit: this.isEdit,
        isCopy: this.isCopy,
        isCopyNew: this.isCopyNew,
      };
      if (this.isCopy) {
        this.modalprocess.datacopy = this.objRecord.name;
      }
      this.handleLoading(false);
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    resetCondition() {
      this.isCreate = false;
      this.isEdit = false;
      this.isCopy = false;
      this.isCopyNew = false;
      this.isDelete = false;
      this.objSelect = [];
      this.objRecord = [];
      this.objDataSave = [];
      this.resultData = [];
      this.dataObjSelect = {};
      this.modalprocess = {};
      this.alertdata = {};
      this.modalAlert = {};
    },
    callGetList() {
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        page: this.page,
        page_size: this.page_size,
      };
      if (this.nameSearch) {
        params.role_name = this.nameSearch;
      }
      let v2 = this.exAccount?.account?.name == "KBank-Exchange" ? true : false;
      return new Promise((resolve, reject) => {
        manageRoleAPI
          .getSearch(params, v2)
          .then((res) => {
            if (res.data) {
              if (res.data.result) {
                let item = res.data.result.items;
                let paging = res.data.result.paging;
                if (item.length > 0) {
                  this.data.push(...res.data.result.items);
                }
                this.data.forEach((elements) => {
                  elements.isShowMore = false;
                });
                this.totalList = this.data.length;
                this.conditionTable = {
                  hasMoreData: paging.has_more_data,
                  isFilters: false,
                };
              }
            }
            this.$nextTick(() => {
              this.handleLoading(false);
              this.isShowSkeleton = false;
              resolve(res.data);
            });
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            reject(error);
          });
      });
    },
    callGetDetail() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.objRecord.id,
      };
      manageRoleAPI
        .getDetailRole(params)
        .then((res) => {
          if (res.data) {
            if (res.data.result) {
              this.objSelect = res.data.result;
              this.dataObjSelect = this.objSelect;
              this.dataEditForCheck = JSON.stringify(this.objSelect);
              if (this.isCopy) {
                let newData = {
                  name: this.objSelect.name,
                  description: this.objSelect.description,
                };
                this.dataEditForCheck = newData;
              }
              this.dataObjSelect.dataEditForCheck = this.dataEditForCheck;
              this.showModalProcess();
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostAddData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_name: this.objDataSave.name,
        role_description: this.objDataSave.description,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postCreateRole(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.resultData = res.data.result;
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_create_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostEditData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
        role_name: this.objDataSave.name,
        role_description: this.objDataSave.description,
        is_active: this.objDataSave.is_active,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postEditRole(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostCopyData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        source_role_id: this.objRecord.id,
        new_role_name: this.objDataSave.name,
        new_role_description: this.objDataSave.description,
        copy_permission: this.objDataSave.copy_permission,
        copy_user: this.objDataSave.copy_user,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postCopyRole(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.resultData = res.data.result;
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_copy_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostCopyDataNew() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        source_role_id: this.objRecord.id,
        new_role_name: this.objDataSave.name,
        new_role_description: this.objDataSave.description,
        copy_permission: this.objDataSave.copy_permission,
        copy_user: this.objDataSave.copy_user,
        copy_application: this.objDataSave.copy_application,
        copy_excould_application: this.objDataSave.copy_excould_application,
      };
      this.objForSave = JSON.stringify(valueSave);
      managePermissionAPI
        .postPermissionCopyRole(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.resultData = res.data.result;
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_copy_success;
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
</style>
