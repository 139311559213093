<template>
  <div>
    <a-form :form="form">
      <div class="user-information-page">
        <div class="title">{{ lbls.user_page.tbl_colum_name }}</div>
        <div class="info">{{ dataInfo?.display_name }}</div>

        <div class="title">{{ lbls.user_page.tbl_colum_fullname }}</div>
        <div class="info">
          {{ dataInfo?.first_name }} {{ dataInfo?.last_name }}
        </div>

        <div class="title">{{ lbls.user_page.tbl_colum_email }}</div>
        <div class="info">{{ dataInfo?.email }}</div>

        <div class="title">{{ lbls.user_page.tbl_colum_status }}</div>
        <div class="status mt-2">
          <a-form-item>
            <a-radio-group
              v-decorator="[
                'status',
                {
                  initialValue: dataInfo.is_active,
                },
              ]"
              button-style="solid"
              size="large"
              @change="(e) => (dataInfo.is_active = e.target.value)"
            >
              <a-radio-button :value="true">
                {{ lbls.user_page.title_status_active }}
              </a-radio-button>
              <a-radio-button :value="false">
                {{ lbls.user_page.title_status_inactive }}
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
        </div>

        <div class="btn-delete">
          <a-button type="danger" @click="handleDelete">
            <a-icon type="delete" />
            {{ lbls.user_page.btn.delete }}
          </a-button>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import Cache from "@/helper/Cache.js";
import manageUsersAPI from "@/core/Page/ManageUserAPI";
import PageLeaveMixin from '@/mixin/PageLeaveMixin'

export default {
  name: "UserInformation",
  components: {},
  mixins: [PageLeaveMixin],
  props: {
    datauser: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataInfo: {
        display_name: "",
        first_name: "",
        last_name: "",
        email: "",
        is_active: false,
      },
      exAccount: {},
      isDelete: false,
      isDeleteUser: false,
      alertData: {},
      modalAlert: {},
      idModal: "",
      titleModal: "",
      formTouch: false,
    };
  },
  watch: {
    datauser: function (newVal) {
      this.dataInfo = newVal;
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "user-information",
      onFieldsChange: (prop) => {
        if (prop.isFieldsTouched()) {
          this.formTouch = true;
          this.$emit("formtouch", "user", this.formTouch, this.dataInfo);
        }
      },
    });
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.dataInfo = this.datauser;
    },
    resetCondition() {
      this.isDeleteUser = false;
      this.alertData = {};
      this.modalAlert = {};
    },
    handleDelete() {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDelete = true;
      this.isDeleteUser = true;
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: this.lbls.user_page.modal.content_delete,
        content: this.dataInfo.display_name + ".",
        okText: this.lbls.user_page.modal.btn.delete,
        okType: "danger",
        cancelText: this.lbls.user_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDelete();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    callPostDelete() {
      let params = {
        account_id: this.exAccount.account.id,
        user_id: this.dataInfo.id,
      };
      manageUsersAPI
        .deleteUser(params)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            setTimeout(() => {
              this.titleModal = this.lbls.user_page.modal.title_success;
              this.contentModal =
                this.lbls.user_page.modal.content_delete_success;
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.handleLoading(true);
          this.errorApi(error);
          this.resetCondition();
        });
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
        onOk: () => {
          this.$router.push({ name: "ManageUsers" });
        },
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
        this.$router.push({ name: "ManageUsers" });
      }, secondsToGo * 1000);
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_mixin.scss";
.user-information-page {
  height: calc(100vh - 350px);
  .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $color-grey-9e;
  }
  .info {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .btn-delete {
    position: absolute;
    bottom: 0;
  }
}
</style>
