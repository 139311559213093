<template>
  <div
    class="default-layout page-layout"
    :class="{ 'disable-menu': menuList?.length == 0 }"
  >
    <a-config-provider :locale="localecode == 'th' ? thTh : enUS">
      <a-layout>
        <a class="menu_mobile" @click="collapsed = !collapsed">
          <img :src="img_logo" />
        </a>
        <div class="ant-layout-sider-group">
          <div class="logo">
            <a>
              <img :src="img_logo" />
            </a>
          </div>
          <div
            class="ant-layout-sider-overlay"
            :class="{ overlayed: !collapsed }"
            @click="collapsed = !collapsed"
          ></div>
          <a-layout-sider v-if="!collapsed" width="70" class="sider-mainmenu">
            <a-menu
              mode="inline"
              :theme="$store.state.themecolor"
              :selected-keys="['Product-Platform-External']"
            >
              <a-menu-item
                v-for="product in allproducts"
                :key="product.name"
                :title="product.display_name"
                :disabled="product.name == 'Product-Platform-External'"
              >
                <a @click="gotoOtherWeb(product)">
                  <img
                    :src="imgblob + '/img/menu/' + product.icon + '.normal.svg'"
                    class="normal"
                  />
                  <img
                    :src="imgblob + '/img/menu/' + product.icon + '.active.svg'"
                    class="active"
                  />
                  <img
                    :src="
                      imgblob + '/img/menu/' + product.icon + '.disable.svg'
                    "
                    class="disabled"
                  />
                </a>
              </a-menu-item>
            </a-menu>
            <a-dropdown placement="topRight" class="dropdown-helpcenter">
              <a
                class="dropdownmenu ant-dropdown-link"
                @click="(e) => e.preventDefault()"
              >
                <a-icon type="question-circle" />
              </a>
              <a-menu
                v-if="menuHelpCenter?.length > 0"
                slot="overlay"
                class="custom-menu-item"
              >
                <a-menu-item
                  v-for="(sub, index) in menuHelpCenter"
                  :key="index"
                >
                  <span @click="gotoHelpCenter(sub)">
                    {{ sub.page.display_name }}
                  </span>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item>
                  <span @click="isModalSLA = true">
                    Service Level Agreement
                  </span>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-layout-sider>
          <a-layout-sider
            v-if="menuList?.length > 0"
            v-model="collapsed"
            collapsible
            width="280"
            :collapsed-width="70"
            :trigger="null"
            class="sider-thismenu"
            :class="{ collapsed: collapsed }"
            ><div class="box-scoll-menu">
              <a-menu mode="inline" :selected-keys="[activeMenu]">
                <template v-if="menuList">
                  <a-menu-item v-for="item in menuList" :key="item.name">
                    <router-link
                      :to="{ name: item.page.route_name }"
                      :title="item.display_name"
                    >
                      <a-icon
                        v-if="item.icon_type == 'ant'"
                        :type="item.icon_name"
                      />
                      <template v-if="item.icon_type == 'custom'">
                        <a-icon
                          v-if="item.icon_name == 'rolemanagement'"
                          :component="RoleManagementSVG"
                        />
                        <a-icon
                          v-if="item.icon_name == 'crossaccount'"
                          :component="CrossAccountSVG"
                        />
                      </template>
                      <span>{{ item.display_name }}</span>
                    </router-link>
                  </a-menu-item>
                </template>
              </a-menu>
            </div>
            <div class="foot-trigger">
              <button
                class="trigger"
                :class="{ collapsed: collapsed }"
                @click="() => (collapsed = !collapsed)"
              >
                <a-icon v-if="collapsed" type="menu-unfold" />
                <a-icon v-if="!collapsed" type="menu-fold" />
              </button>
            </div>
          </a-layout-sider>
        </div>
        <Header :iscollapsed="collapsed" @switchaccount="switchaccount()" />
        <a-layout-content
          :class="{
            collapsed: collapsed,
            'has-emergency': $store.state.showEmergency,
          }"
        >
          <slot></slot>
        </a-layout-content>
        <!-- Modal -->
        <div v-if="isModal">
          <ModalProcess
            :modalprocess="modalprocess"
            @actionModal="actionModal($event)"
          >
          </ModalProcess>
        </div>
      </a-layout>
      <template #renderEmpty>
        <a-empty
          :image="imgEmpty"
          :image-style="{
            height: '150px',
          }"
        />
      </template>
    </a-config-provider>
    <ModalSLA
      v-if="isModalSLA"
      :is-show="isModalSLA"
      @closeModal="closeModalSLA()"
    />
  </div>
</template>
<script>
import _ from "lodash";
import Vue from "vue";
import Header from "@/components/Layout/Header";
import Account from "@/helper/AccountHelper";
import Cache from "@/helper/Cache";
import { config } from "@/config/bzbsconfig.js";
import ModalProcess from "@/components/Modal/ModalProcess.vue";
import redirectmsAPI from "@/core/Page/RedirectmsAPI";
import userAPI from "@/core/Page/ManageUserAPI";
import enUS from "ant-design-vue/es/locale/en_US";
import thTh from "ant-design-vue/es/locale/th_TH";
import RoleManagementSVG from "@/assets/images/Icon/RoleManagement.svg";
import CrossAccountSVG from "@/assets/images/Icon/CrossAccount.svg";
import ModalSLA from "@/components/Modal/ModalSLA.vue";

export default {
  components: {
    Header,
    ModalProcess,
    ModalSLA,
  },
  data() {
    return {
      RoleManagementSVG,
      CrossAccountSVG,
      logoFooter: require("@/assets/images/LogoPowered.png"),
      avatar: require("@/assets/images/avatar.png"),
      iconArrowDown: require("@/assets/images/Icon/Icon_arrow-down.svg"),
      imgEmpty: config.themeUrl + "/img/empty.svg",
      img_logo: config.themeUrl + "/img/logo-icon.svg",
      appName: "Permission Management",
      activeMenu: "",
      selectProduct: "",
      dataUser: [],
      menuList: [],
      isModal: false,
      titleModal: "",
      contentModalNodata: "",
      errtransaction_id: "",
      dataUserAll: [],
      newAccount: "",
      isSwithcSuccess: false,
      idModal: "",
      enUS,
      thTh,
      modalprocess: {},
      allproducts: [],
      menuHelpCenter: [],
      imgblob: config.themeUrl,
      isModalSLA: false,
    };
  },
  computed: {
    exAccount: {
      get() {
        return Cache.get("exAccount");
      },
    },
    collapsed: {
      get() {
        return this.$store.state.collapsed;
      },
      set(newValue) {
        this.$store.commit("setCollapsed", newValue);
      },
    },
  },
  // watch: {
  //   lbls() {
  //     this.callGetUserPermission();
  //     // this.callGetMenu();
  //   },
  // },
  created() {
    this.init();
  },
  methods: {
    getLanguageList() {
      return [
        {
          title: this.lbls.language_title.en,
          img: require("@/assets/images/226-united-states.svg"),
          key: "en",
        },
        {
          title: this.lbls.language_title.th,
          img: require("@/assets/images/238-thailand.svg"),
          key: "th",
        },
      ];
    },
    init() {
      if (!Cache.get("msToken")) {
        this.$router.push({ name: "Login" });
      }
      this.handleSetMenu();
      this.checkPermission();
      // if (this.dataUser) {
      //   if (this.menuLists) {
      //     this.$store.commit("showModalAnnouncement", false);
      //   } else {
      //     this.$store.commit("showModalAnnouncement", true);
      //     // this.callGetUserPermission();
      //   }
      // }
      this.checkcurrenttab();
    },
    callGetMenu(params) {
      Account.callGetMenu(params).catch((error) => {
        this.showLayout = true;
        this.idModal = "error";
        this.handleLoading(true);
        this.errorApi(error);
      });
    },
    handleSetMenu() {
      // let menu =
      //   this.$store.state.menu?.length > 0
      //     ? this.$store.state.menu
      //     : this.$store.state.menuEx;
      this.appName = config.appName;
      this.dataUser = this.exAccount?.account;
      this.allproducts = this.$store.state.menuProduct;
      this.menuList = this.$store.state.menu;
      this.menuHelpCenter = this.$store.state.productHelpCenter?.menus;
      let is_account_bzbs = this.dataUser?.name.includes("Buzzebees Platform");
      if (!is_account_bzbs) {
        this.menuList = this.menuList.filter((item) => {
          return item.name != "crossaccount" && item.name != "cross";
        });
      }
    },
    menuClick({ key }) {
      console.log(key, "menuClick");
      if (window.location.pathname == key) {
        return;
      }
      this.$router.push({
        path: key,
      });
    },
    checkPermission() {
      this.callGetCurrentAccount().then((result) => {
        if (result && (result.owner_account || result.working_account)) {
          const exAccount = {
            account: result.working_account ? result.working_account : null,
            owner_account: result.owner_account ? result.owner_account : null,
          };
          Cache.set("exAccount", exAccount);
          Account.callGetMenu(exAccount?.account?.id).then(() => {
            this.handleSetMenu();
          });
        } else {
          this.gotoUnauthorized();
        }
      });
    },
    gotoUnauthorized() {
      let path = window.location.pathname == "/unauthorized";
      if (!path) {
        this.$router.push({
          name: "unauthorized",
        });
      }
    },
    callGetCurrentAccount() {
      return new Promise((resolve) => {
        redirectmsAPI
          .postCurrentAccount(this.package_name)
          .then((res) => {
            resolve(res.data.result);
          })
          .catch(() => {
            this.handleLoading(true);
          });
      });
    },
    getAccountMenuList() {
      return [
        {
          title: this.lbls.menu.dropdown_account.switchaccount,
          to: {
            name: "switchaccount",
          },
        },
        {
          title: this.lbls.menu.dropdown_account.logout,
          to: {
            name: "logout",
          },
        },
      ];
    },
    switchaccount() {
      this.handleLoading(true);
      this.newAccount = "";
      this.callGetManagedAccount();
    },
    handleMenuClick(e) {
      const nowlocale = Vue.$cookies.get("BzbsLocale");
      if (nowlocale == e.key) {
        return;
      }
      Vue.$cookies.set("BzbsLocale", e);
      window.location.reload();
    },
    callGetManagedAccount() {
      redirectmsAPI
        .getManagedAccount(this.package_name)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            let items = res.data.result.items;
            let tempaccount = [];
            let tempaccountselect = "";
            items.forEach((elements) => {
              let ownermsg = elements.is_owner_account
                ? this.lbls.layoutmaster.modal.title_owner_user
                : "";
              let disabled = elements.id == this.dataUser.id ? true : false;
              tempaccount.push({
                label: elements.name + ownermsg,
                value: elements.id,
                description: elements.description,
                disabled: disabled,
              });
              if (disabled) {
                tempaccountselect = elements.id;
              }
            });
            this.titleModal = this.lbls.layoutmaster.modal.title_get_account;
            this.isModal = true;
            this.modalprocess = {
              visible: true,
              title: this.titleModal,
              dataaccount: tempaccount,
              dataaccountselect: tempaccountselect,
            };
          }
          this.handleLoading(false);
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostSwithcAccount(value) {
      let params = {
        working_account_id: value,
      };
      redirectmsAPI
        .postSwithcAccount(params)
        .then((res) => {
          if (res.data && res.data.is_success) {
            this.idModal = "success";
            this.dataUserAll = [];
            this.isSwithcSuccess = true;
            this.titleModal = this.lbls.layoutmaster.modal.title_switch_account;
            let msg = this.lbls.layoutmaster.modal.msg_content_swithc_account;
            console.log("this.callPostSwithcAccount");
            let position = msg.search("{account}");
            if (position >= 0) {
              msg = msg.replace("{account}", this.newAccountName);
            }
            this.content = msg;
            this.openNotificationWithIcon("success");
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.content,
      });
      setTimeout(() => {
        this.handleLoading(true);
        // location.reload();
        window.location = window.location.origin + "/redirectms";
      }, 500);
    },
    callGetUserPermission() {
      let params = {
        account_id: this.exAccount?.account?.id,
      };
      userAPI
        .getUsePermission(params)
        .then((res) => {
          if (res.data) {
            if (res.data.result) {
              if (res.data.result.features.length > 0) {
                let menulist = [];
                let result = res.data.result.features;
                this.$store.commit("objfeaturelists", result);
                result.forEach((element) => {
                  if (element.name == "Application") {
                    menulist.push({
                      key: "1",
                      title: this.lbls.menu.application,
                      keys: "application",
                      path: "/manageapplication",
                      icontype: "ant",
                      iconname: "appstore",
                    });
                  }
                  if (element.name == "Cross-Account") {
                    menulist.push({
                      key: "2",
                      title: this.lbls.menu.crossaccount,
                      keys: "crossaccount",
                      path: "/managecrossaccount",
                      icontype: "custom",
                      iconname: "crossaccount",
                    });
                  }
                  if (element.name == "Role-And-User") {
                    menulist.push({
                      key: "4",
                      title: this.lbls.menu.rolemanagement,
                      keys: "rolemanagement",
                      path: "/manageroles",
                      icontype: "custom",
                      iconname: "rolemanagement",
                    });
                    menulist.push({
                      key: "5",
                      title: this.lbls.menu.usermanagement,
                      keys: "usermanagement",
                      path: "/manage-users",
                      icontype: "ant",
                      iconname: "user",
                    });
                  }
                  menulist.sort((a, b) => a.key - b.key);
                  // _.forEach(menulist, (column) => {
                  //   column.title = this.lbls.menu[column.keys];
                  // });
                  // this.menuList = menulist;
                });
              } else {
                Cache.remove("menuList");
                this.isShowModal = true;
                this.titleModal = this.lbls.checkpermission.modal.title_warning;
                this.contentModal =
                  this.lbls.checkpermission.modal.msg_error_not_menu;
                this.handleLoading(false);
              }
              this.showLayout = true;
            }
          }
        })
        .catch((error) => {
          this.showLayout = true;
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    checkcurrenttab() {
      _.forEach(this.menuList, (value) => {
        if (value.menus) {
          _.forEach(value.menus, (subitem) => {
            if (
              subitem.page &&
              (this.$router.currentRoute.name == subitem.page.route_name ||
                (this.$router.currentRoute.path.includes(
                  subitem.page.url_path
                ) &&
                  this.$router.currentRoute.path.search(
                    subitem.page.url_path
                  ) == 0))
            ) {
              this.openKeys = [value.name];
              this.activeMenu = subitem.page.name;
            }
          });
        } else {
          if (
            value.page &&
            (this.$router.currentRoute.name == value.page.route_name ||
              (this.$router.currentRoute.path.includes(value.page.url_path) &&
                this.$router.currentRoute.path.search(value.page.url_path) ==
                  0))
          ) {
            this.openKeys = [value.name];
            this.activeMenu = value.page.name;
          }
        }
      });
    },
    closeDrawer() {
      this.isModal = false;
      this.titleModal = "";
      this.contentModalNodata = "";
      this.errtransaction_id = "";
      this.content = "";
    },
    handlemodalOk() {
      this.handleLoading(true);
      if (this.isSwithcSuccess) {
        window.location.href = "checkpermission";
      }
      if (this.dataUserAll?.length > 0 && this.newAccount) {
        this.closeDrawer();
        this.callPostSwithcAccount();
      }
    },
    onChange(e) {
      this.newAccount = e.target.value;
      this.dataresultAccount.forEach((elements) => {
        if (elements.id == this.newAccount) {
          this.newAccountName = elements.name;
        }
      });
    },
    actionModal(value) {
      if (value == "close") {
        setTimeout(() => {
          this.isModal = false;
          return;
        }, 500);
      } else {
        this.handleLoading(true);
        this.isModal = false;
        this.callPostSwithcAccount(value);
      }
    },
    setDisableMenu(product_name) {
      if (product_name != "Package-Management") {
        return true;
      } else {
        return false;
      }
    },
    gotoOtherWeb(menu) {
      window.location = `${menu.url}/redirectms`;
    },
    gotoHelpCenter(menu) {
      console.log(menu);
      window.location =
        this.$store.state.productHelpCenter.url +
        "/redirectms?target_page=" +
        encodeURIComponent(menu.page.route_name);
    },
    closeModalSLA() {
      this.isModalSLA = false;
    },
  },
};
</script>
<style lang="scss" scope>
.ant-layout {
  display: none !important;
}
.custom-menu-item {
  &.ant-dropdown-menu {
    margin-left: 75px !important;
    margin-bottom: -30px !important;
  }
}
</style>
