<template>
  <div class="table-invite-member-page">
    <div class="d-flex justify-content-end my-3">
      <a-input-search
        v-model="searchData"
        :placeholder="lbls.invitation_page.input_search"
        class="input-search"
        size="large"
        @search="onSearch(searchData)"
      >
        <template slot="suffix">
          <a-icon
            v-if="isSearchData"
            type="close-circle"
            theme="filled"
            class="icon-close-search-input"
            @click="closeSearchData()"
          />
        </template>
      </a-input-search>
    </div>

    <a-card v-if="isShowSkeleton">
      <a-skeleton />
    </a-card>
    <div v-else>
      <div class="campaign-list-table">
        <a-table
          id="campaign-list-table"
          row-key="id"
          :columns="columns"
          :data-source="data"
          :scroll="{ y: tableHeight, x: 860 }"
          :pagination="false"
          :locale="{
            filterConfirm: lbls.invitation_page.btn.ok,
            filterReset: lbls.invitation_page.btn.reset,
          }"
          @change="handleTableChange"
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${lbls.btn.search}${column.title}`"
              :value="selectedKeys[0]"
              style="margin-bottom: 8px; display: block; font-size: 12px"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
              "
            >
              {{ lbls.btn.search }}
            </a-button>
            <a-button
              style="width: 90px"
              @click="() => handleTableReset(clearFilters)"
            >
              {{ lbls.btn.reset }}
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template slot="template-email" slot-scope="text">
            {{ text }}
          </template>
          <template slot="template-status" slot-scope="text, record">
            <a-tag v-if="text" :color="'green'" class="tag-status">
              <a-badge
                :status="'success'"
                :text="lbls.invitation_page.tbl_colum_accepted"
              />
            </a-tag>
            <a-tag v-else :color="'red'" class="tag-status">
              <a-badge
                :status="'error'"
                :text="lbls.invitation_page.tbl_colum_unaccepted"
              />
            </a-tag>
            <div v-if="text" class="title-accepted-date">
              {{ formatDateString(record.accepted_date) }}
            </div>
          </template>
          <template slot="template-product-display-name" slot-scope="text">
            <span>{{ text ? text : "-" }}</span>
          </template>
          <template slot="template-role-name" slot-scope="text, record">
            <span v-if="text && text.length > 0" class="role-custom">
              <div v-for="(tag, index) in text" :key="tag.id">
                <div v-if="index <= checkShowMore(record)">
                  <div class="d-flex align-items-center">
                    <div class="w-100">
                      <span v-if="record.auto_assigned_role_names.length > 1"
                        >{{ index + 1 }}.
                      </span>
                      <span>{{ tag }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="text.length > 3"
                class="show-hide-custom mt-2"
                @click="loadMore(record)"
              >
                <span>
                  {{
                    record.isShowMore
                      ? lbls.role_page.btn.hide
                      : lbls.role_page.btn.more
                  }}
                </span>
                <span v-if="!record.isShowMore"> ({{ text.length }})</span>
                <span class="ml-2">
                  <a-icon v-if="record.isShowMore" type="caret-up" />
                  <a-icon v-else type="caret-down" />
                </span>
              </div>
            </span>
            <span v-else> - </span>
          </template>
          <template slot="accepted" slot-scope="text">
            <span class="status-active" :class="{ inactive: !text }">
              {{ text === true ? "Accepted" : "Inaccept" }}
            </span>
          </template>
          <template slot="template-date" slot-scope="text">
            <div>
              {{ formatDateString(text) }}
            </div>
          </template>
          <template slot="template-expired-date" slot-scope="text, record">
            <div
              :class="{
                expired_date: record.is_expired && !record.is_accepted,
              }"
            >
              {{ formatDateString(text) }}
            </div>
          </template>
          <template slot="template-delete" slot-scope="text, record">
            <div class="action-setting">
              <a-button
                type="danger"
                class="btn-cancel"
                ghost
                :disabled="
                  record.is_already_member ||
                  record.is_accepted ||
                  record.is_expired ||
                  !isPermissionDelete
                "
                @click="() => handleDelete(record)"
              >
                {{ lbls.invite_page.btn.btn_cancel_invite }}
              </a-button>
            </div>
          </template>
        </a-table>

        <ShowTotal
          :datashowtitle="data"
          :conditiontable="conditionTable"
          :pagesize="page_size"
          :totallist="totalList"
          @callgetlist="callGetList"
          @setpagesize="setPageSize($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Cache from "@/helper/Cache.js";
import _ from "lodash";
import manageUsersAPI from "@/core/Page/ManageUserAPI";
import ShowTotal from "@/components/Layout/ShowTotal";

export default {
  name: "TableInvite",
  components: { ShowTotal },
  data() {
    return {
      permission_key: "user",
      searchData: null,
      isSearchData: false,
      nameSearch: null,
      isShowSkeleton: true,
      columns: [
        {
          id: "Status",
          title: "Status",
          width: "250px",
          keys: "tbl_colum_accepted",
          dataIndex: "is_accepted",
          scopedSlots: { customRender: "template-status" },
          onFilter: (value, record) => {
            if (value == "member") {
              return record.is_already_member;
            }
            if (value != "member") {
              value = value == "true";
              return record.is_accepted === value && !record.is_already_member;
            }
          },
          filters: [
            {
              text: "tbl_colum_accepted",
              value: "true",
            },
            {
              text: "tbl_colum_unaccepted",
              value: "false",
            },
          ],
        },
        {
          id: "Email",
          title: "Email",
          keys: "tbl_colum_email",
          dataIndex: "invited_email",
          width: "300px",
          scopedSlots: { customRender: "template-email" },
          sorter: (a, b) => a.invited_email.localeCompare(b.invited_email),
        },
        {
          id: "ProductDisplayName",
          title: "Product Display Name",
          keys: "tbl_colum_product_disname",
          dataIndex: "product_display_name",
          width: "300px",
          scopedSlots: { customRender: "template-product-display-name" },
          filters: [],
          onFilter: (value, record) => {
            if (value == "none") {
              return !record.product_display_name;
            } else {
              return record.product_display_name === value;
            }
          },
        },
        {
          id: "Role",
          title: "Role",
          keys: "tbl_colum_role",
          dataIndex: "auto_assigned_role_names",
          width: "300px",
          scopedSlots: { customRender: "template-role-name" },
          filters: [],
          onFilter: (value, record) => {
            if (value == "none") {
              return record.auto_assigned_role_names.length == 0;
            } else {
              if (record.auto_assigned_role_names) {
                let findobj = record.auto_assigned_role_names.find(
                  (o) => o === value
                );
                if (findobj) {
                  record.idfind = findobj;
                }
              }
              return record.idfind === value;
            }
          },
        },
        {
          id: "InviteDate",
          title: "Invite Date",
          keys: "tbl_colum_invite_date",
          dataIndex: "created_date",
          width: "200px",
          scopedSlots: { customRender: "template-date" },
        },
        {
          id: "ExpiredDate",
          title: "Expired Date",
          keys: "tbl_colum_expired_date",
          dataIndex: "expired_date",
          width: "200px",
          scopedSlots: { customRender: "template-expired-date" },
        },
        {
          title: "",
          width: "150px",
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "template-delete" },
        },
      ],
      formatdate: "DD  MMM  YYYY - hh:mm A",
      data: [],
      dataStart: [],
      tableHeight: 0,
      page: 0,
      page_size: 25,
      totalList: 0,
      roleFilters: [],
      productFilters: [],
      conditionTable: {},
      columnsStart: [],
      innerHeight: 0,
      isPermissionDelete: true,
      objRecord: {},
    };
  },
  watch: {
    data() {
      this.setDataFilters();
      this.handlePermission();
      _.forEach(this.data, (element) => {
        if (element.is_already_member) {
          element.is_accepted = true;
        }
      });
    },
    lbls() {
      this.columns = _.cloneDeep(this.columnsStart);
      this.setColumnTable();
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.$nextTick(() => {
      window.addEventListener("resize", this.calTableHeight);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.calTableHeight);
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.setCallGetList().then(() => {
        this.dataStart = this.data;
        this.columnsStart = _.cloneDeep(this.columns);
        this.setColumnTable();
        this.setDataFilters();
      });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.isSearchData = this.searchData != "";
        this.nameSearch = text != undefined ? text : "";
        this.setCallGetList();
      } else {
        return;
      }
    },
    checkShowMore(record) {
      let lengthData = 2;
      if (record.isShowMore) {
        lengthData = record.auto_assigned_role_names.length;
      }
      return lengthData;
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        if (permission_page?.length) {
          this.isPermissionDelete = this.checkArrowPermission(
            "user-invite-delete",
            permission_page
          );
        }
      }
    },
    loadMore(record) {
      record.isShowMore = record.isShowMore == true;
      record.isShowMore = !(record.isShowMore != false);
      this.$forceUpdate();
    },
    closeSearchData(searchData) {
      this.searchData = "";
      this.onSearch(searchData);
      this.$forceUpdate();
    },
    onScroll() {
      this.tableContent.addEventListener("scroll", (event) => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = event.target.scrollTop;
        let isScrollDown = this.lastscrollTop !== event.target.scrollTop;
        let scrollPersent = (currentScroll / maxScroll) * 100;
        if (
          scrollPersent >= 95 &&
          this.conditionTable.hasMoreData &&
          isScrollDown &&
          !this.isFilters
        ) {
          this.conditionTable.hasMoreData = false;
          this.handleLoading(true);
          this.callGetList();
        } else {
          return;
        }
        this.lastscrollTop = event.target.scrollTop;
      });
    },
    setDataFilters() {
      this.roleFilters.push({
        text: "-",
        value: "none",
      });
      this.productFilters.push({
        text: "-",
        value: "none",
      });
      _.forEach(this.data, (element) => {
        if (element.auto_assigned_role_names) {
          _.forEach(element.auto_assigned_role_names, (item) => {
            this.roleFilters.push({
              text: item,
              value: item,
            });
          });
        }
        if (element.product_display_name) {
          this.productFilters.push({
            text: element.product_display_name,
            value: element.product_display_name,
          });
        }
      });
      this.roleFilters = [
        ...new Map(this.roleFilters.map((item) => [item.text, item])).values(),
      ];
      let findcolumnsRole = this.columns.find((o) => o.id == "Role");
      if (findcolumnsRole) {
        findcolumnsRole.filters = this.roleFilters;
      }
      this.productFilters = [
        ...new Map(
          this.productFilters.map((item) => [item.text, item])
        ).values(),
      ];
      let findcolumnsProduct = this.columns.find(
        (o) => o.id == "ProductDisplayName"
      );
      if (findcolumnsProduct) {
        findcolumnsProduct.filters = this.productFilters;
      }
      this.$forceUpdate();
    },
    setColumnTable() {
      _.forEach(this.columns, (column) => {
        if (column.keys) {
          column.title = this.lbls.invitation_page[column.keys];
        }
        if (column.filters) {
          _.forEach(column.filters, (filters) => {
            filters.text = this.lbls.invitation_page[filters.text];
          });
        }
      });
    },
    handleTableSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleTableReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handleTableChange(pagination, filters, sorte, currentData) {
      console.log("pagination", pagination);
      console.log("filters", filters);
      console.log("sorte", sorte);
      console.log("currentData", currentData);
    },
    setCallGetList() {
      return new Promise((resolve) => {
        this.isShowSkeleton = true;
        this.page = 0;
        this.page_size = this.page_size;
        this.data = [];
        this.callGetList().then(() => {
          this.calTableHeight();
          this.tableContent = document.querySelector(".ant-table-body");
          this.onScroll();
          resolve();
        });
      });
    },
    callGetList() {
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        page: this.page,
        page_size: this.page_size,
      };
      if (this.nameSearch) {
        params.filter = this.nameSearch;
      }
      return new Promise((resolve, reject) => {
        manageUsersAPI
          .getInvitation(params)
          .then((res) => {
            if (res.data) {
              if (res.data.result) {
                let item = res.data.result.items;
                let paging = res.data.result.paging;
                if (item.length > 0) {
                  this.data.push(...res.data.result.items);
                  this.data.forEach((elements) => {
                    let first_name = elements.first_name
                      ? elements.first_name + " "
                      : "-";
                    let last_name = elements.last_name
                      ? elements.last_name
                      : "";
                    let display_name = elements.display_name
                      ? elements.display_name
                      : "-";
                    elements.full_name = first_name + last_name;
                    elements.display_name = display_name;
                    elements.isShowMore = false;
                  });
                }
                this.totalList = this.data.length;
                this.conditionTable = {
                  hasMoreData: paging.has_more_data,
                  isFilters: false,
                };
              }
            }
            this.$nextTick(() => {
              this.handleLoading(false);
              setTimeout(() => {
                this.isShowSkeleton = false;
                resolve(res.data);
              }, 300);
            });
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            reject(error);
          });
      });
    },
    handleDelete(record) {
      this.idModal = "confirm";
      this.isDelete = true;
      this.modalbtnok = this.lbls.invitation_page.modal.btn.delete;
      this.titleModal = this.lbls.invitation_page.modal.title_confirm;
      this.contentModal = this.lbls.invitation_page.modal.content_deletel + " ?";
      this.objRecord = record;
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: this.lbls.invitation_page.modal.content_delete,
        content: this.objRecord.invited_email,
        okText: this.lbls.invitation_page.modal.btn.delete,
        okType: "danger",
        cancelText: this.lbls.invitation_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDeleteData();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    callPostDeleteData() {
      let params = {
        account_id: this.exAccount.account.id,
        invitation_id: this.objRecord.id,
      };
      manageUsersAPI
        .deleteInvitation(params)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.isModalProcess = false;
            setTimeout(() => {
              this.titleModal = this.lbls.invitation_page.modal.title_success;
              this.contentModal =
                this.lbls.invitation_page.modal.content_delete_success;
              this.showModalSuccess();
              this.handleLoading(false);
            }, 500);
          }
        })
        .catch((error) => {
          this.isDelete = false;
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      this.setCallGetList();
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_mixin.scss";
.table-invite-member-page {
  .input-search.ant-input-search {
    width: 350px;
    height: 40px;
    justify-content: end;
  }
  .custom-column-email {
    display: flex;
    .column-email-left {
      width: 16px;
    }
    .column-email-right {
      width: calc(100% - 16px);
    }
  }
  .role-custom {
    .img-rotate {
      transform: rotate(180deg);
    }
    .show-hide-custom {
      cursor: pointer;
      font-size: 12px;
      color: $color-primary !important;
      font-weight: 600;
    }
  }
  .title-accepted-date {
    font-size: 10px;
    font-weight: 500;
    line-height: 2;
    opacity: 0.6;
  }
  .disabled {
    color: var(--text-light-text-disable) !important;
    pointer-events: none;
  }
  .action-setting {
    .ant-btn {
      color: $color-danger;
      border-color: $color-danger;
      height: 30px;
      padding: 0;
      &:hover,
      &:active,
      &:focus {
        color: $color-danger;
        border-color: $color-danger;
        outline: $color-danger;
      }
    }
  }
}
</style>
