import CommonMixin from "@/mixin/CommonMixin.js";
import Cache from "@/helper/Cache.js";

export default {
  mixins: [CommonMixin],

  data() {
    return {
      visible: false,
      confirmLoading: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.formTouch || this.$store.state.formTouch) {
      this.unsaveModal(
        () => {
          this.$root.$refs.save.saveForm(true).then((result) => {
            if (result == "IsLeavpage") {
              this.$store.commit("formTouch", false);
              this.successResponse(null, to);
            }
          });
        },
        () => {
          this.$store.commit("formTouch", false);
          next();
        }
      );
    } else {
      next();
    }
  },
  created() {},
  methods: {
    handleFormTouch(value) {
      this.$store.commit("formTouch", value);
    },
    unsaveModal(okCallback, cancelCallback) {
      const h = this.$createElement;
      const btnclose = h(
        "button",
        {
          attrs: {
            class: "ant-btn",
          },
          on: {
            click: function () {
              modal.destroy();
            },
          },
        },
        this.lbls.btn.cancel
      );
      const btnwithout = this.$createElement(
        "button",
        {
          attrs: {
            class: "ant-btn",
          },
          on: {
            click: function () {
              modal.destroy();
              Cache.remove("userAutoFill");
              Cache.remove("selectapp");
              cancelCallback();
            },
          },
        },
        this.lbls.main.popup_leave
      );
      const btnsave = this.$createElement(
        "button",
        {
          attrs: {
            class: "ant-btn ant-btn-primary",
          },
          on: {
            click: function () {
              modal.destroy();
              okCallback();
            },
          },
        },
        this.lbls.btn.save
      );
      const modal = this.$confirm({
        title: this.lbls.main.sure_to_leave_page,
        class: "wrap-leave-modal",
        centered: true,
        width: "600",
        content: [
          h("p", this.lbls.main.language_unsaved_data_lost),
          h(
            "div",
            {
              attrs: {
                class: "ant-modal-confirm-btns wrap-btn",
              },
            },
            [btnclose, btnwithout, btnsave]
          ),
        ],
      });
    },
    onLeave(callback) {
      callback();
    },
    cancelForm() {
      if (this.formTouch) {
        this.unsaveModal(() => {
          this.$router.go();
        });
      } else {
        this.$router.go();
      }
    },
  },
};
